import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Resizer from "react-image-file-resizer";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import "react-aspect-ratio/aspect-ratio.css";
import AspectRatio from "react-aspect-ratio";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { firestoreAddUser, firestoreUpdateUser } from "../../store/actions/user.action";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import placeholder from "../../asset/img/avatar.png";
import UserDialogDelete from "./dialog/user_dialog_delete";
import UserAvatarDialogSelect from "./dialog/user_avatar_dialog_select";
import UserAvatarDialogUpload from "./dialog/user_avatar_dialog_upload";
import UserAvatarDialogCamera from "./dialog/user_avatar_dialog_camera";
import {
  MODE_ADD,
  MODE_EDIT,
  MODE_SELECT,
  MODE_NONE,
  CAMERA,
  UPLOAD,
  LEVELS,
} from "../../config/stringConfig";
import { MODEL_USER } from "../../model/user";

class UserDetail extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      loading: false,
      showPincode: false,
      mode: MODE_NONE, // 'add', 'select', 'update', 'none'
      user: MODEL_USER.model,
      error: MODEL_USER.error,
      require: MODEL_USER.require,
      regex: MODEL_USER.regex,
      inputRegex: MODEL_USER.inputRegex,
      errorMess: MODEL_USER.errorMess,
      tooltip:
        "If the total break time is less than 30mins per working day, it will be rounded to 30mins.",
      userDialogDelete: false,
      avatarDialogSelect: false,
      avatarDialogUpload: false,
      avatarDialogCamera: false,
    };
  }

  // Chuyển state từ props
  static getDerivedStateFromProps(nextProps, prevState) {
    const oldMode = prevState.mode;
    const newMode = nextProps.mode;
    const oldId = prevState.user.id;
    const newId = nextProps.userId;
    if (oldMode !== newMode || oldId !== newId) {
      // Nếu có sự thay đổi về mode hoặc id thì update state
      const users = nextProps.users;
      const user = users && users.find((item) => item.id === newId);
      return {
        mode: newMode,
        showPincode: false,
        user: user || MODEL_USER.model,
        error: MODEL_USER.error,
      };
    }
    return null;
  }

  // ====================== Functions ====================== //

  handleClickShowPincode = () => {
    const { showPincode } = this.state;
    this.setState({
      showPincode: !showPincode,
    });
  };

  handleChange = (e) => {
    const { user, error, inputRegex } = this.state;
    const id = e.target.id;
    const value = e.target.value;
    const inputCondition = inputRegex && inputRegex[id];
    if (!inputCondition || (inputCondition && inputCondition.test(value))) {
      this.setState({
        user: {
          ...user,
          [id]: value,
        },
        error: {
          ...error,
          [id]: this.handleValidate({ id, value }),
        },
      });
    }
  };

  handleSelect = (e) => {
    const { user, error } = this.state;
    const value = e.target.value;
    const id = e.target.name;
    this.setState(
      {
        user: {
          ...user,
          [id]: value,
        },
        error: {
          ...error,
          [id]: this.handleValidate({ id, value }),
        },
      },
      () => {
        this.handleLevelRole(id, value);
      }
    );
  };

  handleLevelRole = (id, value) => {
    const { user } = this.state;
    if (id === "level") {
      const individualRoster = true;
      const shopRoster = value === "Level 4" ? true : false;
      this.setState({
        user: {
          ...user,
          shopRoster,
          individualRoster,
        },
      });
    }
  };

  handleChangePhone = (value) => {
    const { user, error } = this.state;
    this.setState({
      user: {
        ...user,
        phone: value,
      },
      error: {
        ...error,
        phone: this.handleValidate({ id: "phone", value }),
      },
    });
  };

  handleDOBChange = (date) => {
    const dob = moment(date).format("DD/MM/YYYY");
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        dob,
      },
    });
  };

  toggleCheck = (id) => {
    const { user } = this.state;
    const value = user[id] || false;
    this.setState({
      user: {
        ...user,
        [id]: !value,
      },
    });
  };
  // ====================== Avatar ====================== //

  openAvatarSelect = () => {
    const { mode } = this.state;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    if (disabled) {
      return;
    }
    this.setState({
      avatarDialogSelect: true,
    });
  };

  closeAvatarSelect = () => {
    this.setState({
      avatarDialogSelect: false,
    });
  };

  confirmAvatarSelect = (type) => {
    if (type === CAMERA) {
      this.setState({
        avatarDialogSelect: false,
        avatarDialogCamera: true,
      });
    } else if (type === UPLOAD) {
      this.setState({
        avatarDialogSelect: false,
        avatarDialogUpload: true,
      });
    }
  };

  // ====================== Camera ====================== //

  closeCameraSelect = () => {
    this.setState({
      avatarDialogCamera: false,
    });
  };

  confirmCameraSelect = (uri) => {
    const { user } = this.state;
    this.setState({
      avatarDialogCamera: false,
      user: {
        ...user,
        avatar: uri,
      },
    });
  };
  // ====================== Upload ====================== //

  closeUploadSelect = () => {
    this.setState({
      avatarDialogUpload: false,
    });
  };

  confirmUploadSelect = (files) => {
    const { user } = this.state;
    this.setState(
      {
        avatarDialogUpload: false,
      },
      () => {
        Resizer.imageFileResizer(
          files[0],
          512,
          512,
          "jpg",
          96,
          0,
          (uri) => {
            this.setState({
              user: {
                ...user,
                avatar: uri,
              },
            });
          },
          "base64"
        );
      }
    );
  };

  // ====================== Delete ====================== //
  openUserDelete = () => {
    this.setState({
      userDialogDelete: true,
    });
  };
  closeUserDelete = () => {
    this.setState({
      userDialogDelete: false,
    });
  };
  confirmUserDelete = () => {
    this.setState(
      {
        userDialogDelete: false,
      },
      () => {
        this.props.onCancel();
      }
    );
  };
  // ====================== Hàm xử lý validation ====================== //

  handleValidate = ({ id, value }) => {
    // xử lý validate
    if (id === "id") {
      return;
    }
    const { require, regex, errorMess } = this.state;
    const condition = regex[id];
    const errorString = errorMess[id];
    // Xử lý validate
    if (value && value.length > 0) {
      if (condition && condition.test(value)) {
        // valid
        return false;
      } else {
        // lỗi
        return errorString;
      }
    } else {
      if (require[id]) {
        // require
        return "Please fill out this field";
      } else {
        // empty
        return false;
      }
    }
  };

  checkAllValid = () => {
    const { user } = this.state;
    let error = {};
    let allValid = true;
    Object.entries(user).forEach((data, index) => {
      const id = data[0];
      const value = data[1];
      if (id === "id") {
        return;
      }
      const isError = this.handleValidate({ id, value });
      error = {
        ...error,
        [id]: isError,
      };
      if (isError) {
        allValid = false;
      }
    });
    if (!allValid) {
      // Ko hợp lệ tất cả các field
      this.setState({
        error,
      });
    }
    return allValid;
  };

  // ====================== Firebase Functions ====================== //

  handleAddOrUpdateUser = () => {
    const { mode } = this.state;
    const allValid = this.checkAllValid();
    if (allValid) {
      // Hợp lệ
      if (mode === MODE_ADD) {
        this.hanldeAddUser();
      } else if (mode === MODE_EDIT) {
        this.hanldeUpdateUser();
      }
    }
  };

  hanldeAddUser = () => {
    const { user, error } = this.state;
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.firestoreAddUser({ user }, (response) => {
          this.setState(
            {
              loading: false,
            },
            () => {
              if (response) {
                // thành công
                this.props.onCancel();
              } else {
                this.setState({
                  // Xử lý lỗi trùng user name
                  error: {
                    ...error,
                    username: "This username has been created. Please use other name.",
                  },
                });
              }
            }
          );
        });
      }
    );
  };

  hanldeUpdateUser = () => {
    const { user } = this.state;
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.firestoreUpdateUser({ user }, () => {
          this.setState(
            {
              loading: false,
            },
            () => {
              this.props.onSelect();
            }
          );
        });
      }
    );
  };

  // ====================== Render Component ====================== //

  // ====================== Render Left Side ====================== //

  renderLeftSide = () => {
    return (
      <Grid item xs={3}>
        {this.renderAvatar()}
        <Grid item xs={12}>
          {this.renderActiveDropdown()}
        </Grid>
        {this.renderRosterView()}
      </Grid>
    );
  };

  renderAvatar = () => {
    const { user, mode } = this.state;
    const avatar = user && user.avatar;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <div className="avatar-container">
        <AspectRatio
          ratio="1/1"
          style={{ maxWidth: "200px", maxHeight: "200px", opacity: disabled ? "0.3" : 1 }}
        >
          <img
            src={avatar || placeholder}
            className="avatar-logo"
            alt="avatar-logo"
            onClick={() => this.openAvatarSelect()}
          />
        </AspectRatio>
      </div>
    );
  };

  renderActiveDropdown = () => {
    const { user, mode } = this.state;
    const active = user && user.active;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <FormControl
        variant="outlined"
        margin="dense"
        required
        fullWidth
        style={{ marginTop: 10, marginBottom: 10 }}
        disabled={disabled}
      >
        <InputLabel id="activeLabel">Active</InputLabel>
        <Select
          labelId="activeLabel"
          id="active"
          name="active"
          value={active}
          onChange={this.handleSelect}
          label="Active"
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Deactivated</MenuItem>
        </Select>
      </FormControl>
    );
  };

  renderRosterView = () => {
    const { user, mode } = this.state;
    const shopRoster = user?.shopRoster || false;
    const individualRoster = user?.individualRoster || false;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={12}>
        <span>Roster View:</span>
        <div style={{ justifyContent: "center" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={individualRoster}
                onChange={() => this.toggleCheck("individualRoster")}
                name="individualRoster"
                color="primary"
                disabled={disabled}
              />
            }
            label="Individual"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={shopRoster}
                onChange={() => this.toggleCheck("shopRoster")}
                name="shopRoster"
                color="primary"
                disabled={disabled}
              />
            }
            label="Shop"
          />
        </div>
      </Grid>
    );
  };

  // ====================== Render Right Side ====================== //

  renderRightSide = () => {
    return (
      <Grid item xs={9}>
        <Grid container spacing={2}>
          {this.renderUserName()}
          {this.renderPincode()}
          {this.renderFirstName()}
          {this.renderLastName()}
          {this.renderPhone()}
          {this.renderEmail()}
          {this.renderDOB()}
          {this.renderLevelDropDown()}
          {this.renderButtonBar()}
        </Grid>
      </Grid>
    );
  };

  renderUserName = () => {
    const { user, error, mode } = this.state;
    const username = (user && user.username) || "";
    const lengthUsername = 32 - (username && username.length) || 0;
    const errorUsername = (error && error.username) || "";
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6} className="count-string">
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          type="text"
          value={username}
          error={!!errorUsername}
          helperText={errorUsername}
          autoFocus
          disabled={disabled}
          onChange={this.handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">{lengthUsername}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderPincode = () => {
    const { user, error, mode, showPincode } = this.state;
    const pincode = (user && user.pincode) || "";
    const errorPincode = (error && error.pincode) || "";
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6}>
        <FormControl
          fullWidth
          variant="outlined"
          margin="dense"
          error={!!errorPincode}
          disabled={disabled}
        >
          <InputLabel htmlFor="pincode" required>
            Pincode
          </InputLabel>
          <OutlinedInput
            id="pincode"
            value={pincode}
            onChange={this.handleChange}
            type={showPincode ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPincode}
                >
                  {showPincode ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>{errorPincode}</FormHelperText>
        </FormControl>
      </Grid>
    );
  };

  renderFirstName = () => {
    const { user, error, mode } = this.state;
    const firstName = (user && user.firstName) || "";
    const errorFirstName = (error && error.firstName) || "";
    const lengthFirstName = 32 - (user && user.firstName && user.firstName.length) || 0;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6} className="count-string">
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="firstName"
          label="First Name"
          name="firstName"
          type="text"
          disabled={disabled}
          value={firstName}
          autoFocus
          error={!!errorFirstName}
          helperText={errorFirstName}
          onChange={this.handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">{lengthFirstName}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderLastName = () => {
    const { user, error, mode } = this.state;
    const lastName = (user && user.lastName) || "";
    const errorLastName = (error && error.lastName) || "";
    const lengthLastName = 32 - (user && user.lastName && user.lastName.length) || 0;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6} className="count-string">
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="lastName"
          label="Last Name"
          name="lastName"
          type="text"
          disabled={disabled}
          value={lastName}
          autoFocus
          error={!!errorLastName}
          helperText={errorLastName}
          onChange={this.handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">{lengthLastName}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderPhone = () => {
    const { user, error, mode } = this.state;
    const phone = (user && user.phone) || "";
    const errorPhone = (error && error.phone) || "";
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6}>
        <PhoneInput
          onlyCountries={["vn", "nz", "us", "au"]}
          country="nz"
          onChange={this.handleChangePhone}
          autoFormat={false}
          value={phone}
          disabled={disabled}
          inputProps={{
            name: "phone",
            required: true,
            autoFocus: true,
            label: "Phone",
            id: "phone",
            placeholder: "Phone number",
            margin: "normal",
          }}
          inputStyle={{
            width: "100%",
            borderColor: !!errorPhone ? "red" : "rgba(0, 0, 0, 0.23)",
            color: disabled ? "#c8c8c8" : "#333",
          }}
        />
        <FormHelperText style={{ color: "red" }}>{errorPhone}</FormHelperText>
      </Grid>
    );
  };

  renderEmail = () => {
    const { user, error, mode } = this.state;
    const email = (user && user.email) || "";
    const errorEmail = error && error.email;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="email"
          label="Email"
          name="email"
          type="email"
          disabled={disabled}
          value={email}
          autoFocus
          error={!!errorEmail}
          helperText={errorEmail}
          onChange={this.handleChange}
        />
      </Grid>
    );
  };

  renderDOB = () => {
    const { user, mode } = this.state;
    const dob = user && user.dob && moment(user.dob, "DD/MM/YYYY");
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            autoOk
            className="date-picker"
            variant="inline"
            inputVariant="outlined"
            fullWidth
            value={dob}
            label="DOB"
            format="DD/MM/YYYY"
            InputAdornmentProps={{ position: "end" }}
            onChange={this.handleDOBChange}
            margin="dense"
            disabled={disabled}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    );
  };

  renderLevelDropDown = () => {
    const { user, mode } = this.state;
    const level = user?.level;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6}>
        <FormControl variant="outlined" margin="dense" required fullWidth disabled={disabled}>
          <InputLabel id="levelLabel">Level</InputLabel>
          <Select
            labelId="levelLabel"
            id="level"
            name="level"
            value={level}
            onChange={this.handleSelect}
          >
            {LEVELS?.map((item, index) => (
              <MenuItem key={index} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  // ====================== Render Buttons ====================== //

  renderButtonBar = () => {
    const { mode } = this.state;
    if (mode === MODE_SELECT) {
      return (
        <Grid item md={12} xs={12}>
          {this.renderEditButton()}
        </Grid>
      );
    }
    if (mode === MODE_EDIT) {
      return (
        <Grid item md={12} xs={12}>
          {this.renderDeleteButton()}
          {this.renderSaveButton()}
        </Grid>
      );
    }
    // if (mode === MODE_ADD){
    //     return (
    //         <Grid item md={12} xs={12}>
    //             {this.renderCancelButton()}
    //             {this.renderSaveButton()}
    //         </Grid>
    //     );
    // }
    return (
      <Grid item md={12} xs={12}>
        {this.renderCancelButton()}
        {this.renderSaveButton()}
      </Grid>
    );
  };

  renderCancelButton = () => {
    const { loading, mode } = this.state;
    const disabled = mode === MODE_NONE || loading;
    return (
      <Button
        variant="contained"
        color="default"
        style={{ float: "left" }}
        disabled={disabled}
        disableElevation
        onClick={() => this.props.onCancel()}
      >
        Cancel
      </Button>
    );
  };

  renderSaveButton = () => {
    const { loading, mode } = this.state;
    const disabled = mode === MODE_NONE || loading;
    if (loading) {
      return (
        <Button
          variant="contained"
          color="default"
          disabled={disabled}
          style={{ float: "right" }}
          disableElevation
          startIcon={<CircularProgress size={15} />}
        >
          Save
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        style={{
          backgroundColor: "green",
          float: "right",
          color: "#fff",
          opacity: disabled ? 0.2 : 1,
        }}
        disableElevation
        onClick={() => this.handleAddOrUpdateUser()}
      >
        Save
      </Button>
    );
  };

  renderDeleteButton = () => {
    const { loading } = this.state;
    return (
      <Button
        variant="contained"
        color="secondary"
        style={{ backgroundColor: "red", float: "left" }}
        disabled={loading}
        disableElevation
        onClick={() => this.openUserDelete()}
      >
        Delete
      </Button>
    );
  };

  renderEditButton = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        style={{ float: "right" }}
        disableElevation
        onClick={() => this.props.onEdit()}
      >
        Edit
      </Button>
    );
  };

  // ====================== Render Main ====================== //

  render() {
    const { user, userDialogDelete, avatarDialogSelect, avatarDialogUpload, avatarDialogCamera } =
      this.state;
    return (
      <Grid container spacing={2}>
        {this.renderLeftSide()}
        {this.renderRightSide()}
        <UserDialogDelete
          dialogOpen={userDialogDelete}
          handleClose={this.closeUserDelete}
          handleConfirm={this.confirmUserDelete}
          user={user}
        />
        <UserAvatarDialogSelect
          dialogOpen={avatarDialogSelect}
          handleClose={this.closeAvatarSelect}
          handleSelect={this.confirmAvatarSelect}
        />
        <UserAvatarDialogUpload
          dialogOpen={avatarDialogUpload}
          handleClose={this.closeUploadSelect}
          handleConfirm={this.confirmUploadSelect}
        />
        <UserAvatarDialogCamera
          dialogOpen={avatarDialogCamera}
          handleClose={this.closeCameraSelect}
          handleTakePhoto={this.confirmCameraSelect}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.userReducer.users,
});

const mapDispatchToProps = {
  firestoreAddUser,
  firestoreUpdateUser,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(UserDetail);
