import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TableSortLabel,
} from "@material-ui/core";
import noAvatar from "../../asset/img/no-avatar.png";
import { getHeaderByRange, secondsToHoursAndMinuteFormat } from "../../util/refine.timetracking";
class TimeTrackingTable extends Component {
  // ====================== Inits ====================== //

  constructor(props) {
    super(props);
    this.state = {};
  }

  // ====================== Functions ====================== //

  // ====================== Data Functions ====================== //

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderTableHead = () => {
    const { by, direction, range, checkAll } = this.props;
    const header = getHeaderByRange(range);
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              checked={checkAll}
              onChange={() => this.props.handleCheckAll()}
              inputProps={{ "aria-label": "select all desserts" }}
              color="primary"
            />
          </TableCell>
          {header?.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding="default"
              sortDirection={by === headCell.id ? direction : false}
            >
              {this.renderTableSortLabel(headCell)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  renderTableSortLabel = (headCell) => {
    const { by, direction } = this.props;
    if (headCell.sortable) {
      return (
        <TableSortLabel
          active={by === headCell.id}
          direction={by === headCell.id ? direction : "asc"}
          onClick={() => this.props.handleSort(headCell.id)}
        >
          {headCell.label}
        </TableSortLabel>
      );
    }
    return headCell.label;
  };

  renderTableBody = () => {
    const { reportData } = this.props;
    return (
      <TableBody>
        {reportData?.map((section, key) => this.renderTableSections({ section, key }))}
      </TableBody>
    );
  };

  renderTableSections = ({ section, key }) => {
    const data = section?.result;
    return (
      <React.Fragment key={key}>
        {data?.map((row, index) => this.renderTableRow({ row, index }))}
        {this.renderRowFooter({ section, key })}
      </React.Fragment>
    );
  };

  renderTableRow = ({ row, index }) => {
    const working = secondsToHoursAndMinuteFormat(row?.workSeconds);
    const breaking = secondsToHoursAndMinuteFormat(row?.breakSeconds);
    // const checked = this.isSelected(row);
    return (
      <TableRow hover role="checkbox" aria-checked={false} tabIndex={-1} key={index}>
        <TableCell align="left" padding="checkbox">
          <Checkbox
            color="primary"
            inputProps={{ "aria-labelledby": `table-checkbox-${index}` }}
            onChange={() => this.props.handleCheck(row)}
          />
        </TableCell>
        <TableCell align="left">{row?.username}</TableCell>
        <TableCell align="left">{row?.fullName}</TableCell>
        <TableCell align="center">{row?.date}</TableCell>
        <TableCell align="center">{row?.day}</TableCell>
        {this.renderInOutCells(row?.check)}
        <TableCell align="center">{working}</TableCell>
        <TableCell align="center">{breaking}</TableCell>
      </TableRow>
    );
  };

  renderInOutCells = (check) => {
    const { range } = this.props;
    const cells = [];
    for (let i = 0; i < range; i++) {
      const cell = check[i];
      if (cell) {
        cells.push(
          <TableCell align="center" key={i} className="report-data-cell">
            <p style={{ color: cell?.type === "in" ? "green" : "red" }}>{cell?.time}</p>
            {this.renderPhoto(cell)}
            {this.renderLocation(cell)}
          </TableCell>
        );
      } else {
        cells.push(
          <TableCell align="center" key={i}>
            {""}
          </TableCell>
        );
      }
    }
    return cells;
  };

  renderPhoto = (cell) => {
    const { showPhoto } = this.props;
    if (showPhoto) {
      if (cell && cell.screenshot) {
        return <img className="table-photo" src={`${cell.screenshot}`} alt={cell.id} />;
      } else {
        return <img className="table-photo" src={noAvatar} alt={cell.id} />;
      }
    }
    return null;
  };

  renderLocation = (cell) => {
    const { showLocation } = this.props;
    if (showLocation) {
      if (cell && cell.subUrl) {
        return <p className="table-location">{cell.subUrl}</p>;
      } else {
        return <p>no location data</p>;
      }
    }
    return null;
  };

  renderRowFooter = ({ section, key }) => {
    const { range } = this.props;
    const sumWorking = secondsToHoursAndMinuteFormat(section?.sumWorking);
    const sumBreaking = secondsToHoursAndMinuteFormat(section?.sumBreaking);
    const emptyCell = [];
    for (let i = 0; i < range + 4; i++) {
      emptyCell.push(
        <TableCell align="center" key={i}>
          {""}
        </TableCell>
      );
    }
    return (
      <TableRow
        hover
        role="checkbox"
        aria-checked={false}
        tabIndex={-1}
        selected={false}
        className="total-row"
        key={`footer-${key}`}
      >
        <TableCell align="left" padding="checkbox"></TableCell>
        {emptyCell}
        <TableCell align="center">{sumWorking}</TableCell>
        <TableCell align="center">{sumBreaking}</TableCell>
      </TableRow>
    );
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    console.log(this.props.checked);
    return (
      <Paper style={{ width: "100%", marginTop: 10, marginBottom: 30 }}>
        <TableContainer className="report-table time-tracking-table">
          <Table stickyHeader aria-label="sticky table">
            {this.renderTableHead()}
            {this.renderTableBody()}
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}

export default compose(connect(null, null))(TimeTrackingTable);
