import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";
import lodash from "lodash";
import { Loading } from "../../component/loading";
import RosterDialogWeekEdit from "./dialogs/roster_dialog_week_edit";
import { LEVELS } from "../../config/stringConfig";

class RosterTable extends Component {
  // ====================== Inits ====================== //
  constructor() {
    super();
    this.state = {
      edited: [],
      editError: [],
      header: [
        { id: "companyName", label: "Company Name", align: "left", sortable: false },
        { id: "initial", label: "Initial", align: "left", sortable: false },
        { id: "phone", label: "Phone", align: "left", sortable: false },
        { id: "email", label: "Email", align: "left", sortable: false },
        { id: "createdOn", label: "Created on", align: "center", sortable: false },
      ],
      dialogEdit: false,
      selectedStaff: null,
    };
  }

  // ====================== Functions ====================== //

  isSelected = (data) => {
    const { selected } = this.props;
    const exist = selected && selected.find((item) => item.itemCode === data.itemCode);
    return exist ? true : false;
  };

  isCheckedAll = () => {
    const { selected, reportData } = this.props;
    return selected && reportData && selected.length === reportData.length ? true : false;
  };

  getHeader = () => {
    const { branch, dates } = this.props;
    const companyName = branch && branch.companyName;
    const header = [{ id: "companyName", label: companyName, align: "left", sortable: false }];
    const headerDate =
      dates &&
      dates.map((item, index) => {
        const label = moment(item, "ddd DD MMM YYYY").format("ddd DD");
        return {
          id: index,
          label,
          align: "center",
          sortable: false,
        };
      });
    const result = [...header, ...headerDate];
    return result;
  };

  // ====================== Data Functions ====================== //

  openDialogEdit = ({ staff }) => {
    const { copying, loading } = this.props;
    if (loading || copying) {
      return;
    }
    this.setState({
      selectedStaff: staff,
      dialogEdit: true,
    });
  };

  closeDialogEdit = () => {
    this.setState({
      selectedStaff: null,
      dialogEdit: false,
    });
  };

  getWorkingStaff = ({ date }) => {
    const { rosters, branch } = this.props;
    if (branch) {
      const workingStaff = rosters?.filter((item) => {
        const sameBranch = item?.branch === branch?.id;
        const sameDate = item?.date === date;
        const work = item?.off === false;
        const existStaff = branch?.staffs?.includes(item?.staff);
        return sameBranch && sameDate && existStaff && work;
      });
      const listUnique = workingStaff ? lodash.uniqBy(workingStaff, "staff") : [];
      return listUnique?.length;
    }
    return 0;
  };

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderTableHead = () => {
    const { orderBy, order, data } = this.props;
    const header = this.getHeader();
    const disabled = !data;
    return (
      <TableHead>
        <TableRow>
          {header &&
            header.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                padding="default"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {this.renderTableSortLabel(headCell, disabled)}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  };

  renderTableSortLabel = (headCell, disabled) => {
    const { orderBy, order } = this.props;
    if (headCell.sortable) {
      return (
        <TableSortLabel
          disabled={disabled}
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : "asc"}
          onClick={() => this.props.handleSort(headCell.id)}
        >
          {headCell.label}
        </TableSortLabel>
      );
    }
    return headCell.label;
  };

  renderTableBody = () => {
    const { branch, loading, users } = this.props;
    const staffs = branch && branch.staffs;
    const data = users && staffs && users.filter((item) => staffs.includes(item.id));
    if (!loading) {
      return (
        <TableBody>
          {data && data.map((row, index) => this.renderTableRow({ row, index }))}
          {this.renderTableRowTotal()}
        </TableBody>
      );
    }
    return <TableBody />;
  };

  renderTableRow = ({ row, index }) => {
    const { dates } = this.props;
    const fullName = `${row?.firstName} ${row?.lastName}`;
    const level = row?.level;
    const color = LEVELS?.find((item) => item.name === level)?.color;
    return (
      <TableRow
        hover
        key={index}
        style={{ cursor: "pointer" }}
        onClick={() => this.openDialogEdit({ staff: row })}
      >
        <TableCell align="left" style={{ borderLeft: `5px solid ${color}` }}>
          <Tooltip title={level} placement="top">
            <span>{fullName}</span>
          </Tooltip>
        </TableCell>
        {dates && dates.map((date, i) => this.renderTableCell({ date, index: i, staff: row }))}
      </TableRow>
    );
  };

  renderTableCell = ({ date, index, staff }) => {
    return (
      <TableCell index={index} key={index} align="center">
        {this.renderCellLabel({ date, staff })}
      </TableCell>
    );
  };

  renderCellLabel = ({ date, staff }) => {
    const { rosters, branch, branchs } = this.props;
    const staffId = staff.id;
    const roster = rosters && rosters.find((item) => item.staff === staffId && item.date === date);
    const off = roster && roster.off;
    if (off === false) {
      const rosterBranch = roster && roster.branch;
      if (branch.id === rosterBranch) {
        // Nếu là roster branch này
        // Work At
        const workAt = roster && roster.workAt;
        const wSeconds = workAt && workAt.seconds;
        const workDate = wSeconds && moment(workAt.toDate()).format("hh:mm A");
        // End At
        const endAt = roster && roster.endAt;
        const eSeconds = endAt && endAt.seconds;
        const endDate = eSeconds && moment(endAt.toDate()).format("hh:mm A");
        // Work String
        return (
          <div>
            <span>{workDate}</span>
            <br />
            <span>{endDate}</span>
          </div>
        );
      } else {
        // nếu không phải
        const selectedBranch = branchs && branchs.find((item) => item.id === rosterBranch);
        const intial = selectedBranch && selectedBranch.initial;
        return <span style={{ color: "orange" }}>{intial}</span>;
      }
    }
    const label = roster ? "OFF" : "";
    return <span style={{ color: "red" }}>{label}</span>;
  };

  renderTableRowTotal = () => {
    const { dates } = this.props;
    return (
      <TableRow>
        <TableCell align="left">Total</TableCell>
        {dates && dates.map((date, i) => this.renderTableCellTotal({ date, index: i }))}
      </TableRow>
    );
  };

  renderTableCellTotal = ({ date, index }) => {
    const totalStaff = this.getWorkingStaff({ date });
    return (
      <TableCell index={index} key={index} align="center">
        {totalStaff}
      </TableCell>
    );
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    const { dialogEdit, selectedStaff } = this.state;
    const { loading, branch, dates } = this.props;
    return (
      <Paper style={{ width: "100%", marginTop: 10, marginBottom: 30 }}>
        <TableContainer className="report-table roster-table">
          <Table stickyHeader aria-label="sticky table" style={{ tableLayout: "fixed" }}>
            {this.renderTableHead()}
            {this.renderTableBody()}
          </Table>
          <Loading loading={loading} />
        </TableContainer>
        <RosterDialogWeekEdit
          staff={selectedStaff}
          dates={dates}
          dialogOpen={dialogEdit}
          branch={branch}
          handleClose={this.closeDialogEdit}
        />
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.userReducer.users,
  rosters: state.rosterReducer.rosters,
  branchs: state.branchReducer.branchs,
});

// const mapDispatchToProps = {

// }

export default compose(connect(mapStateToProps, null))(RosterTable);
