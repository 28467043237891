import firebase from "firebase";
import { orderBy } from "lodash";
import { REF_BRANCH, BRANCH_LIST_SUCCESS } from "../ref";

// -------------------------- Branch List --------------------------

export const firestoreGetBranchList = (callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const branchRef = firebase.firestore().collection(REF_BRANCH);
  branchRef
    .where("owner", "==", owner)
    .get()
    .then((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        getGetBranchSuccess(dispatch, data);
      }
      if (callback) {
        callback();
      }
    });
};

const subscribeBranchList = [];
export const firestoreSnapShotBranchList = (callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const branchRef = firebase.firestore().collection("branch");
  const unsubBranchList = branchRef.where("owner", "==", owner).onSnapshot((snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      const ordered = data && orderBy(data, "position", "asc");
      getGetBranchSuccess(dispatch, ordered);
      if (callback) {
        callback();
      }
    }
  });
  subscribeBranchList.push(unsubBranchList);
};

export const unSnapShotBranchList = () => (dispatch) => {
  subscribeBranchList.forEach((subscriber) => {
    // Xóa bỏ subscribe snapshot nếu out
    subscriber();
  });
  subscribeBranchList.length = 0;
};

const getGetBranchSuccess = (dispatch, data) => {
  dispatch({
    type: BRANCH_LIST_SUCCESS,
    payload: data,
  });
};

// -------------------------- Branch Add --------------------------

export const firestoreAddBranch =
  ({ branch }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const branchRef = firebase.firestore().collection(REF_BRANCH);
    const companyName = branch && branch.companyName;
    branchRef
      .where("owner", "==", owner)
      .where("companyName", "==", companyName)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const branchList = snapshot.docs.map((doc) => doc.data());
          if (branchList.length === 0) {
            // Chưa có
            const id = branchRef.doc().id;
            const data = {
              // tạo branch data
              ...branch,
              id,
              createAt: firebase.firestore.FieldValue.serverTimestamp(),
              owner,
            };
            branchRef
              .doc(id)
              .set(data, { merge: true })
              .then(() => {
                if (callback) {
                  callback(data);
                }
              });
          } else {
            if (callback) {
              callback(false);
            }
          }
        }
      });
  };

// -------------------------- Branch Update --------------------------
export const firestoreUpdateBranch =
  ({ branch }, callback) =>
  (dispatch) => {
    const branchRef = firebase.firestore().collection(REF_BRANCH);
    const id = branch.id;
    const data = {
      ...branch,
      updateAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    branchRef
      .doc(id)
      .update(data)
      .then(() => {
        if (callback) {
          callback();
        }
      });
  };

// -------------------------- Branch Delete --------------------------
export const firestoreDeleteBranch =
  ({ branch }, callback) =>
  (dispatch) => {
    const branchRef = firebase.firestore().collection(REF_BRANCH);
    const id = branch.id;
    branchRef
      .doc(id)
      .delete()
      .then(() => {
        if (callback) {
          callback();
        }
      });
  };
