import { LOGIN_LOADING, LOGIN_SUCCESS, LOGIN_FAILED } from "../ref";

const initState = {
  loginError: "",
  loading: false,
};

const loginReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginError: "",
        loading: false,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        loginError: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default loginReducer;
