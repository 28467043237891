import firebase from "firebase";
import moment from "moment";
import { orderBy } from "lodash";
import { REQUEST_SENT } from "../../config/stringConfig";
import { REF_REQUEST, REQUEST_LIST_SUCCESS, REQUEST_LIST_PENDING_SUCCESS } from "../ref";

// -------------------------- Request List --------------------------
const subscribeRequest = [];
export const firestoreSnapshotRequest = (callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const start = moment().subtract(1, "months")._d;
  const end = moment()._d;
  const unsubRequest = firebase
    .firestore()
    .collection(REF_REQUEST)
    .where("owner", "==", owner)
    .where("createAt", ">=", start)
    .where("createAt", "<=", end)
    .onSnapshot((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        const ordered = orderBy(data, "createAt", "desc");
        snapshotRequestSuccess(dispatch, ordered);
        if (callback) {
          callback();
        }
      }
    });
  subscribeRequest.push(unsubRequest);
};

export const unSubRequest = () => (dispatch) => {
  subscribeRequest.forEach((subscriber) => {
    // Xóa bỏ subscribe snapshot nếu out
    subscriber();
  });
  subscribeRequest.length = 0;
};

const snapshotRequestSuccess = (dispatch, data) => {
  dispatch({
    type: REQUEST_LIST_SUCCESS,
    payload: data,
  });
};

// -------------------------- Request Update --------------------------

export const firestoreUpdateRequest = ({ request }, callback) => (dispatch) => {
  const requestRef = firebase.firestore().collection(REF_REQUEST);
  const id = request?.id;
  requestRef
    .doc(id)
    .update(request)
    .then(() => {
      if (callback) {
        callback(true);
      }
    })
    .catch(() => {
      if (callback) {
        callback(false);
      }
    });
};

// -------------------------- Badge --------------------------
const subscribeBadge = [];
export const firestoreSnapshotPendingRequest = (callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const start = moment().subtract(1, "months")._d;
  const end = moment()._d;
  const unsubRequest = firebase
    .firestore()
    .collection(REF_REQUEST)
    .where("owner", "==", owner)
    .where("status", "==", REQUEST_SENT)
    .where("createAt", ">=", start)
    .where("createAt", "<=", end)
    .onSnapshot((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        snapshotPendingRequestSuccess(dispatch, data);
        if (callback) {
          callback();
        }
      }
    });
  subscribeBadge.push(unsubRequest);
};

const snapshotPendingRequestSuccess = (dispatch, data) => {
  dispatch({
    type: REQUEST_LIST_PENDING_SUCCESS,
    payload: data,
  });
};

export const unSubBadge = () => (dispatch) => {
  subscribeBadge.forEach((subscriber) => {
    // Xóa bỏ subscribe snapshot nếu out
    subscriber();
  });
  subscribeBadge.length = 0;
};
