import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CssBaseline, Container, Grid, Button } from "@material-ui/core";
import { LibraryAddCheckOutlined, AddOutlined, EditOutlined } from "@material-ui/icons";
import moment from "moment";
import { orderBy } from "lodash";
import {
  firestoreSnapShotBranchList,
  unSnapShotBranchList,
} from "../../store/actions/branch.action";
import {
  // firestoreSnapShotTimeTracking,
  // unSnapShotTimeTracking,
  firestoreTimeTrackingHandleTasks,
  firestoreGetTimeTracking,
} from "../../store/actions/time_tracking.action";
import { firestoreSnapShotUserList, unSnapShotUserList } from "../../store/actions/user.action";
import Navbar from "../00_Nav/nav_bar";
import RightNav from "../00_Nav/right_nav";
import { MODE_NONE, MODE_EDIT } from "../../config/stringConfig";
import TimeTrackingBranchDropDown from "./component/time_tracking_branch";
import TimeTrackingFromTo from "./component/time_tracking_from_to";
import TimeTrackingSearch from "./component/time_tracking_search";
import TimeTrackingCheckBox from "./component/time_tracking_check_box";
import TimeTrackingDialogAdd from "./dialog/time_tracking_add";
import TimeTrackingTable from "./time_tracking_table";
import TimeTrackingEditTable from "./time_tracking_edit";
// import TimeTrackingDialogEditSelected from "./dialog/time_tracking_edit_selected";
import {
  timeTrackingToReport,
  secondsToHoursAndMinuteFormat,
} from "../../util/refine.timetracking";
import { exportToCSV } from "./functions/csv_converter";

class ReportTimeTracking extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      branch: "",
      from: moment().startOf("isoWeek"),
      to: moment().endOf("isoWeek"),
      loading: false,
      mode: MODE_NONE,
      user: null,
      photo: false,
      location: false,
      dialogAdd: false,
      by: "fullName",
      direction: "asc",
      checked: [],
    };
  }

  componentDidMount = () => {
    this.props.firestoreSnapShotUserList(); // get users
    this.props.firestoreSnapShotBranchList(); // get Branch
  };

  componentWillUnmount = () => {
    this.props.unSnapShotBranchList(); // bỏ listen branch
    this.props.unSnapShotUserList(); // bỏ listen user
    // this.props.unSnapShotTimeTracking(); // bỏ listen time tracking
  };

  // ====================== Hàm xử lý ====================== //

  selectUser = (user) => {
    this.setState(
      {
        user,
      },
      () => {
        this.updateData();
      }
    );
  };

  handleSelect = (e) => {
    const value = e.target.value;
    const id = e.target.name;
    this.setState({
      [id]: value,
    });
  };

  handleDateChange = (id, value) => {
    this.setState({
      [id]: value,
    });
  };

  handleCheckPhoto = () => {
    const { photo } = this.state;
    this.setState({
      photo: !photo,
    });
  };

  handleCheckLocation = () => {
    const { location } = this.state;
    this.setState({
      location: !location,
    });
  };

  openEditMode = () => {
    this.setState({
      mode: MODE_EDIT,
    });
  };

  closeEditMode = () => {
    this.setState({
      mode: MODE_NONE,
    });
  };

  updateAfterEdit = () => {
    this.setState(
      {
        mode: MODE_NONE,
      },
      () => this.viewReportTimeTracking()
    );
  };

  // ====================== Hàm table ====================== //

  handleSort = (id) => {
    const { by, direction, reportData } = this.state;
    const isAsc = by === id && direction === "asc";
    const newDirection = isAsc ? "desc" : "asc";
    const ordered = orderBy(reportData, [by], [newDirection]);
    this.setState({
      by: id,
      direction: newDirection,
      reportData: ordered,
    });
  };

  handleCheckAll = () => {};

  handleCheck = (data) => {};

  // ====================== Hàm dialogs ====================== //

  openDialogAdd = () => {
    this.setState({
      dialogAdd: true,
    });
  };

  closeDialogAdd = () => {
    this.setState({
      dialogAdd: false,
    });
  };

  // ====================== Firebase Functions ====================== //

  // viewReportTimeTracking = () => {
  //   const { from, to, branch } = this.state;
  //   if (from && to && branch) {
  //     this.setState(
  //       {
  //         loading: true,
  //       },
  //       () => {
  //         this.props.unSnapShotTimeTracking();
  //         setTimeout(() => {
  //           this.props.firestoreSnapShotTimeTracking({ branch, from, to }, () => {
  //             this.setState(
  //               {
  //                 loading: false,
  //               },
  //               () => this.updateData()
  //             );
  //           });
  //         }, 50);
  //       }
  //     );
  //   }
  // };

  viewReportTimeTracking = () => {
    const { from, to, branch } = this.state;
    if (from && to && branch) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.props.firestoreGetTimeTracking({ branch, from, to }, () => {
            this.setState(
              {
                loading: false,
              },
              () => this.updateData()
            );
          });
        }
      );
    }
  };

  updateData = () => {
    const { users, timeTracking } = this.props;
    const { from, to, by, direction, user } = this.state;
    let inOutData = timeTracking;
    if (user) {
      inOutData = timeTracking.filter((item) => item.user === user.id);
    }
    const report = timeTrackingToReport({
      users,
      timeTracking: inOutData,
      from,
      to,
    });
    const reportData = orderBy(report?.data, [by], [direction]);
    const totalWorking = report?.totalWorking;
    const totalBreaking = report?.totalBreaking;
    const range = report?.range;
    this.setState({
      range,
      reportData,
      totalWorking,
      totalBreaking,
    });
  };

  handleDownload = () => {
    const { branchs } = this.props;
    const { branch, reportData, from, to, totalWorking, range } = this.state;
    exportToCSV({ reportData, branch, branchs, from, to, totalWorking, range });
  };

  // ====================== Render Component ====================== //

  renderSectionBranch = () => {
    const { branch, mode } = this.state;
    const disabled = mode === MODE_EDIT;
    return (
      <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">
        <Grid item md={2} xs={12}>
          <h3 className="regular-title">Timesheet Details</h3>
        </Grid>
        <Grid item md={2} xs={12}>
          <TimeTrackingBranchDropDown
            branch={branch}
            disabled={disabled}
            handleSelect={this.handleSelect}
          />
        </Grid>
      </Grid>
    );
  };

  renderSectionFromTo = () => {
    const { branch, mode, from, to, loading } = this.state;
    const disabled = mode === MODE_EDIT || !branch || loading;
    return (
      <TimeTrackingFromTo
        from={from}
        to={to}
        loading={loading}
        disabled={disabled}
        onDateChange={this.handleDateChange}
        onViewClick={this.viewReportTimeTracking}
        onDownLoadClick={this.handleDownload}
      />
    );
  };

  renderSectionFilter = () => {
    const { photo, location, mode } = this.state;
    if (mode === MODE_NONE) {
      return (
        <Grid container spacing={3} direction="row" justify="flex-start" alignItems="center">
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <TimeTrackingSearch selectUser={this.selectUser} />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12} className="report-checkbox">
            <TimeTrackingCheckBox
              photo={photo}
              location={location}
              onCheckPhoto={this.handleCheckPhoto}
              onCheckLocation={this.handleCheckLocation}
            />
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Grid container spacing={1} direction="row" justify="flex-end" alignItems="center">
              {this.renderButtonAddRecord()}
              {this.renderButtonEditSelected()}
              {this.renderButtonEdit()}
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  renderButtonAddRecord = () => {
    const { reportData } = this.state;
    const disabled = !reportData;
    return (
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 3, backgroundColor: disabled ? "#e0e0e0" : "#4caf50" }}
          startIcon={<AddOutlined />}
          disabled={disabled}
          onClick={() => this.openDialogAdd()}
        >
          Add record
        </Button>
      </Grid>
    );
  };

  renderButtonEditSelected = () => {
    const { checked } = this.state;
    const disabled = checked?.length > 0 ? false : true;
    return (
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 3 }}
          startIcon={<LibraryAddCheckOutlined />}
          disabled={disabled}
          onClick={() => this.openDialogSelected()}
        >
          Edit selected ({checked?.length})
        </Button>
      </Grid>
    );
  };

  renderButtonEdit = () => {
    const { reportData } = this.state;
    const disabled = !reportData;
    return (
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 3 }}
          startIcon={<EditOutlined />}
          disabled={disabled}
          onClick={() => this.openEditMode()}
        >
          Edit
        </Button>
      </Grid>
    );
  };

  renderSectionTotal = () => {
    const { totalWorking, totalBreaking, mode } = this.state;
    if (mode === MODE_NONE) {
      const workingString = secondsToHoursAndMinuteFormat(totalWorking);
      const breakingString = secondsToHoursAndMinuteFormat(totalBreaking);
      const finalWorking = workingString || "--:--";
      const finalBreaking = breakingString || "--:--";
      return (
        <Grid container spacing={3} direction="row" justify="flex-end" alignItems="center">
          <Grid
            item
            md={6}
            sm={12}
            style={{ textAlign: "right", fontWeight: "bold", fontSize: 15 }}
          >
            Total: <span style={{ color: "#1a76d2" }}>{finalWorking}</span> |{" "}
            <span style={{ color: "red" }}>{finalBreaking}</span>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  renderTimeTrackingTable = () => {
    const {
      mode,
      reportData,
      photo,
      location,
      by,
      direction,
      range,
      checked,
      checkAll,
    } = this.state;
    if (mode === MODE_NONE) {
      return (
        <TimeTrackingTable
          reportData={reportData}
          showPhoto={photo}
          showLocation={location}
          by={by}
          range={range}
          direction={direction}
          handleSort={this.handleSort}
          handleCheck={this.handleCheck}
          handleCheckAll={this.handleCheckAll}
          checked={checked}
          checkAll={checkAll}
        />
      );
    }
    return null;
  };

  renderEditTable = () => {
    const { mode, reportData, range, branch } = this.state;
    if (mode === MODE_EDIT) {
      return (
        <TimeTrackingEditTable
          reportData={reportData}
          range={range}
          mode={mode}
          branch={branch}
          handleCancel={this.closeEditMode}
          handleSuccess={this.updateAfterEdit}
        />
      );
    }
    return null;
  };

  // ====================== Render Main ====================== //

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to={"/signin"} />;
    const { dialogAdd, branch } = this.state;
    return (
      <div className="root-container">
        <CssBaseline />
        <Navbar title="Report Time Tracking" right={<RightNav />} />
        <Container maxWidth="xl" className="report report-time-tracking">
          {this.renderSectionBranch()}
          {this.renderSectionFromTo()}
          {this.renderSectionFilter()}
          {this.renderSectionTotal()}
          {this.renderTimeTrackingTable()}
          {this.renderEditTable()}
        </Container>
        <TimeTrackingDialogAdd
          dialogOpen={dialogAdd}
          handleClose={this.closeDialogAdd}
          branch={branch}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  timeTracking: state.timeTrackingReducer.timeTracking,
  users: state.userReducer.users,
  branchs: state.branchReducer.branchs,
});

const mapDispatchToProps = {
  firestoreSnapShotBranchList,
  unSnapShotBranchList,
  firestoreSnapShotUserList,
  unSnapShotUserList,
  // firestoreSnapShotTimeTracking,
  // unSnapShotTimeTracking,
  firestoreGetTimeTracking,
  firestoreTimeTrackingHandleTasks,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ReportTimeTracking);
