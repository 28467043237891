import React from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { Menu, PowerSettingsNewOutlined } from "@material-ui/icons";
import logo from "../../config/img/logo.png";
import { firebaseLogout } from "../../store/actions/login.action";
import { URL, SANDBOX, VERSION } from "../../config/config";
import rosterSVG from "../../asset/svg/roster.svg";
import userSVG from "../../asset/svg/user.svg";
import buildingSVG from "../../asset/svg/building.svg";
import requestSVG from "../../asset/svg/request.svg";
import reportSVG from "../../asset/svg/report.svg";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const SignInLink = (props) => {
  const classes = useStyles();
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <div className="version">{VERSION}</div>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap>
            {props.title ? props.title : ""}
            {SANDBOX ? " - SandBox" : ""}
          </Typography>
          <div className="appbar-right">{props.right ? props.right : null}</div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <img src={logo} alt="logo" className="nav-logo" />
        </div>
        <Divider />
        <List>
          <ListItem button component="a" href={URL.home}>
            <Tooltip title="User" aria-label="User" placement="top">
              <ListItemIcon>
                <img className="side-menu-icon" alt="User" src={userSVG} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText>User</ListItemText>
          </ListItem>

          <ListItem button component="a" href={URL.branch}>
            <Tooltip title="Branch" aria-label="Branch" placement="top">
              <ListItemIcon>
                <img className="side-menu-icon" alt="Branch" src={buildingSVG} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText>Branch</ListItemText>
          </ListItem>

          <ListItem button component="a" href={URL.roster}>
            <Tooltip title="Roster" aria-label="Roster" placement="top">
              <ListItemIcon>
                <img className="side-menu-icon" alt="Roster" src={rosterSVG} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText>Roster</ListItemText>
          </ListItem>

          <ListItem button component="a" href={URL.request}>
            <Tooltip title="Request" aria-label="Request" placement="top">
              <ListItemIcon>
                <img className="side-menu-icon" alt="Request" src={requestSVG} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText>Request</ListItemText>
          </ListItem>

          <ListItem button component="a" href={URL.reportManagement}>
            <Tooltip title="Reports" aria-label="Reports" placement="top">
              <ListItemIcon>
                <img className="side-menu-icon" alt="Reports" src={reportSVG} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText>Reports</ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={props.firebaseLogout}>
            <ListItemIcon>
              <PowerSettingsNewOutlined />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  firebaseLogout,
})(SignInLink);
