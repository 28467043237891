import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
} from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import { Loading } from "../../component/loading";

class BranchStaffTable extends Component {
  // ====================== Inits ====================== //
  constructor() {
    super();
    this.state = {
      edited: [],
      editError: [],
      header: [
        { id: "username", label: "Username", align: "left", sortable: false },
        { id: "fullName", label: "Full Name", align: "left", sortable: false },
        { id: "phone", label: "Phone", align: "left", sortable: false },
        { id: "dob", label: "DOB", align: "center", sortable: false },
        { id: "#", label: "#", align: "center", sortable: false },
      ],
      selectedOrder: null,
    };
  }

  // ====================== Functions ====================== //

  isSelected = (data) => {
    const { selected } = this.props;
    const exist = selected && selected.find((item) => item.itemCode === data.itemCode);
    return exist ? true : false;
  };

  isCheckedAll = () => {
    const { selected, reportData } = this.props;
    return selected && reportData && selected.length === reportData.length ? true : false;
  };

  // ====================== Data Functions ====================== //

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderTableHead = () => {
    const { users, orderBy, order } = this.props;
    const { header } = this.state;
    const disabled = !(users && users.length > 0);
    return (
      <TableHead>
        <TableRow>
          {header &&
            header.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                padding="default"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {this.renderTableSortLabel(headCell, disabled)}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  };

  renderTableSortLabel = (headCell, disabled) => {
    const { orderBy, order } = this.props;
    if (headCell.sortable) {
      return (
        <TableSortLabel
          disabled={disabled}
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : "asc"}
          onClick={() => this.props.handleSort(headCell.id)}
        >
          {headCell.label}
        </TableSortLabel>
      );
    }
    return headCell.label;
  };

  renderTableBody = () => {
    const { users, loading, branch } = this.props;
    const staffs = branch && branch.staffs;
    const data = users && staffs && users.filter((item) => staffs.includes(item.id));
    if (!loading) {
      return (
        <TableBody>
          {data && data.map((row, index) => this.renderTableRow({ row, index }))}
        </TableBody>
      );
    }
    return <TableBody />;
  };

  renderTableRow = ({ row, index }) => {
    const firstName = row && row.firstName;
    const lastName = row && row.lastName;
    const fullName = `${firstName} ${lastName}`;
    return (
      <TableRow hover key={index} style={{ cursor: "pointer" }}>
        <TableCell align="left">{row.username}</TableCell>
        <TableCell align="left">{fullName}</TableCell>
        <TableCell align="left">{row.phone}</TableCell>
        <TableCell align="center">{row.dob}</TableCell>
        {this.renderDelete({ row })}
      </TableRow>
    );
  };

  renderDelete = ({ row }) => {
    const { disabled } = this.props;
    return (
      <TableCell align="center">
        <IconButton
          aria-label="delete"
          disabled={disabled}
          onClick={() => this.props.onDelete(row)}
          style={{ padding: 0 }}
        >
          <DeleteOutline style={{ color: disabled ? "#c8c8c8" : "red" }} />
        </IconButton>
      </TableCell>
    );
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    const { loading } = this.props;
    return (
      <div
        style={{
          width: "100%",
          marginTop: 10,
          marginBottom: 30,
          borderWidth: 1,
          borderColor: "#c8c8c8",
          borderStyle: "solid",
        }}
      >
        <TableContainer className="report-table">
          <Table stickyHeader aria-label="sticky table">
            {this.renderTableHead()}
            {this.renderTableBody()}
          </Table>
          <Loading loading={loading} />
        </TableContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.userReducer.users,
});

export default compose(connect(mapStateToProps, null))(BranchStaffTable);
