import firebase from "firebase";
import { orderBy } from "lodash";
import { allProgress } from "../../component/functions";
import { LEVELS } from "../../config/stringConfig";
import { REF_USER, USER_LIST_SUCCESS, REF_BRANCH } from "../ref";

// -------------------------- User List --------------------------

export const firestoreGetUserList = (callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const userRef = firebase.firestore().collection(REF_USER);
  userRef
    .where("owner", "==", owner)
    .get()
    .then((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        getGetUserListSuccess(dispatch, data);
      }
      if (callback) {
        callback();
      }
    });
};

const subscribeUserList = [];
export const firestoreSnapShotUserList = () => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const userRef = firebase.firestore().collection(REF_USER);
  const unsubUserList = userRef.where("owner", "==", owner).onSnapshot((snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      const ordered = orderBy(
        data,
        [
          (item) => {
            return LEVELS.findIndex((l) => l.name === item?.level);
          },
          (item) => {
            return `${item?.firstName} ${item?.lastName}`;
          },
        ],
        ["desc", "asc"]
      );
      getGetUserListSuccess(dispatch, ordered);
    }
  });
  subscribeUserList.push(unsubUserList);
};

const getGetUserListSuccess = (dispatch, data) => {
  dispatch({
    type: USER_LIST_SUCCESS,
    payload: data,
  });
};

export const unSnapShotUserList = () => (dispatch) => {
  subscribeUserList.forEach((subscriber) => {
    // Xóa bỏ subscribe snapshot nếu out
    subscriber();
  });
  subscribeUserList.length = 0;
};

// -------------------------- User Add --------------------------

export const firestoreAddUser =
  ({ user }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const userRef = firebase.firestore().collection(REF_USER);
    const username = user && user.username;
    userRef
      .where("owner", "==", owner)
      .where("username", "==", username)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const userList = snapshot.docs.map((doc) => doc.data());
          if (userList.length === 0) {
            // Chưa có
            const id = userRef.doc().id;
            const data = {
              // tạo branch data
              ...user,
              id,
              createAt: firebase.firestore.FieldValue.serverTimestamp(),
              owner,
            };
            userRef
              .doc(id)
              .set(data, { merge: true })
              .then(() => {
                if (callback) {
                  callback(data);
                }
              });
          } else {
            if (callback) {
              callback(false);
            }
          }
        }
      });
  };

// -------------------------- User Update --------------------------

export const firestoreUpdateUser =
  ({ user }, callback) =>
  (dispatch) => {
    const userRef = firebase.firestore().collection(REF_USER);
    const id = user.id;
    userRef
      .doc(id)
      .update(user)
      .then(() => {
        if (callback) {
          callback();
        }
      });
  };

// -------------------------- User Delete --------------------------

export const firestoreDeleteUser =
  ({ user }, callback) =>
  (dispatch) => {
    // Xóa user
    const userRef = firebase.firestore().collection(REF_USER);
    userRef
      .doc(user.id)
      .delete()
      .then(() => {
        if (callback) {
          callback();
        }
      });
    // Xóa user trong branch
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const branchRef = firebase.firestore().collection(REF_BRANCH);
    branchRef
      .where("owner", "==", owner)
      .where("staffs", "array-contains", user.id)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const branchs = snapshot.docs.map((doc) => doc.data());
          const refine = branchs?.map((item) => {
            const staffs = item?.staffs;
            const newStaffs = staffs.filter((s) => s !== user.id);
            return {
              ...item,
              staffs: newStaffs,
            };
          });
          refine.forEach((item) => {
            branchRef.doc(item?.id).update(item);
          });
        }
      });
  };

// -------------------------- User Import --------------------------

// Nhập csv user (mới)
export const firestoreImportUserByJson =
  ({ json }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const userRef = firebase.firestore().collection(REF_USER);
    userRef
      .where("owner", "==", owner)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          const allPromise =
            json &&
            json.map((item) => {
              const exist = data.find((d) => d.username === item.username);
              if (exist) {
                // tồn tại, lấy id
                const id = exist.id;
                const newData = {
                  ...exist,
                  ...item, // ghi đè
                  updateAt: firebase.firestore.FieldValue.serverTimestamp(),
                };
                return userRef.doc(id).update(newData);
              } else {
                // tạo id data
                const id = userRef.doc().id;
                const newData = {
                  ...item,
                  id,
                  owner,
                  createAt: firebase.firestore.FieldValue.serverTimestamp(),
                };
                return userRef.doc(id).set(newData, { merge: true });
              }
            });
          allProgress(allPromise, (p) => {
            const percent = `${p.toFixed(0)}%`;
            if (p === 100 && callback) {
              callback("completed");
            } else {
              callback(percent);
            }
          });
        }
      });
  };

// -------------------------- Update Level Roster Import --------------------------

export const firestoreUpdateRosterView = () => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const userRef = firebase.firestore().collection(REF_USER);
  userRef.where("owner", "==", owner).onSnapshot((snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      data.forEach((item) => {
        const level = item.level;
        const individualRoster = true;
        const shopRoster = level === "Level 4" ? true : false;
        const update = {
          ...item,
          individualRoster,
          shopRoster,
        };
        console.log("update", update);
        userRef.doc(item.id).update(update);
      });
    }
  });
};
