export const MODEL_BRANCH = {
  model: {
    id: null,
    companyName: "",
    initial: "",
    contactEmail: "",
    contactPhone: "",
    serviceLocation: "",
    position: "0",
  },
  error: {
    companyName: "",
    initial: "",
    contactEmail: "",
    contactPhone: "",
    serviceLocation: "",
  },
  require: {
    companyName: true,
    initial: true,
    contactEmail: true,
    contactPhone: false,
    serviceLocation: true,
  },
  regex: {
    companyName: /^[a-zA-Z0-9\s]{1,150}$/, // 150 không kí tự
    contactEmail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // Email format
    contactPhone: /^[0-9.+\-,()\s]{9,13}$/, // 9 tới 11 số , kèm . + - ()
  },
  inputRegex: {
    companyName: /^[a-zA-Z0-9\s]{0,150}$/, // 150 không kí tự
    contactPhone: /^[0-9.+\-,()\s]{0,13}$/, // 9 tới 11 số , kèm . + - ()
    position: /^[0-9\s]$/,
  },
  errorMess: {
    companyName: "Invalid Company Name. Must contain 1-150 characters, numbers and alphabets.",
    companyNumber: "Invalid Company Number. Must contain 1-15 characters, numbers only.",
    contactEmail: "Invalid Email Addresss.",
    contactPhone: "Invalid Phone Number.",
  },
};
