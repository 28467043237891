import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { orderBy } from "lodash";
import { Loading } from "../../component/loading";
import { LEVELS } from "../../config/stringConfig";

class UserTable extends Component {
  // ====================== Inits ====================== //
  constructor() {
    super();
    this.state = {
      edited: [],
      editError: [],
      header: [
        { id: "username", label: "Username", align: "left", sortable: true },
        { id: "fullName", label: "Full Name", align: "left", sortable: true },
        { id: "email", label: "Email", align: "left", sortable: false },
        { id: "phone", label: "Phone", align: "left", sortable: false },
        { id: "dob", label: "DOB", align: "center", sortable: false },
        { id: "rosterView", label: "Roster View", align: "center", sortable: false },
        { id: "active", label: "Status", align: "center", sortable: false },
        { id: "level", label: "Level", align: "center", sortable: false },
      ],
      orderId: "username",
      order: "asc",
      selectedOrder: null,
    };
  }

  // ====================== Functions ====================== //

  isSelected = (data) => {
    const { selected } = this.props;
    const exist = selected && selected.find((item) => item.itemCode === data.itemCode);
    return exist ? true : false;
  };

  isCheckedAll = () => {
    const { selected, reportData } = this.props;
    return selected && reportData && selected.length === reportData.length ? true : false;
  };

  handleSort = (id) => {
    const { orderId, order } = this.state;
    const isAsc = orderId === id && order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderId: id,
    });
  };

  // ====================== Data Functions ====================== //

  refineData = () => {
    const { users } = this.props;
    const { orderId, order } = this.state;
    const data = users?.map((item) => {
      const fullName = `${item?.firstName} ${item?.lastName}`;
      return {
        ...item,
        fullName,
      };
    });
    const result = data && orderBy(data, orderId, order);
    return result;
  };

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderTableHead = () => {
    const { users, orderId, order } = this.props;
    const { header } = this.state;
    const disabled = !(users && users.length > 0);
    return (
      <TableHead>
        <TableRow>
          {header &&
            header.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                padding="default"
                sortDirection={orderId === headCell.id ? order : false}
              >
                {this.renderTableSortLabel(headCell, disabled)}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  };

  renderTableSortLabel = (headCell, disabled) => {
    const { orderId, order } = this.state;
    if (headCell.sortable) {
      return (
        <TableSortLabel
          disabled={disabled}
          active={orderId === headCell.id}
          direction={orderId === headCell.id ? order : "asc"}
          onClick={() => this.handleSort(headCell.id)}
        >
          {headCell.label}
        </TableSortLabel>
      );
    }
    return headCell.label;
  };

  renderTableBody = () => {
    const { loading } = this.props;
    if (!loading) {
      const data = this.refineData();
      return (
        <TableBody>{data?.map((row, index) => this.renderTableRow({ row, index }))}</TableBody>
      );
    }
    return <TableBody />;
  };

  renderTableRow = ({ row, index }) => {
    const fullName = row?.fullName;
    const active = row?.active;
    const isActive = active ? "Active" : "Deactivated";
    const level = row?.level;
    const color = LEVELS?.find((item) => item.name === level)?.color;
    return (
      <TableRow
        hover
        key={index}
        onClick={() => this.props.onRowClick(row)}
        style={{ cursor: "pointer" }}
      >
        <TableCell align="left">{row?.username}</TableCell>
        <TableCell align="left">{fullName}</TableCell>
        <TableCell align="left">{row?.email}</TableCell>
        <TableCell align="left">{row?.phone}</TableCell>
        <TableCell align="center">{row?.dob}</TableCell>
        {this.renderRosterView({ row })}
        <TableCell align="center">{isActive}</TableCell>
        <TableCell align="center" style={{ color }}>
          {level}
        </TableCell>
      </TableRow>
    );
  };

  renderRosterView = ({ row }) => {
    const individual = row?.individualRoster ? "Individual " : "";
    const shop = row?.shopRoster ? "Shop " : "";
    const breakLine = individual && shop ? "& " : "";
    const string = `${individual}${breakLine}${shop}`;
    return <TableCell align="center">{string}</TableCell>;
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    const { loading } = this.props;
    return (
      <Paper style={{ width: "100%", marginTop: 10, marginBottom: 30 }}>
        <TableContainer className="report-table">
          <Table stickyHeader aria-label="sticky table">
            {this.renderTableHead()}
            {this.renderTableBody()}
          </Table>
          <Loading loading={loading} />
        </TableContainer>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.userReducer.users,
});

export default compose(connect(mapStateToProps, null))(UserTable);
