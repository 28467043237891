/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  InputAdornment,
  FormHelperText,
} from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Autocomplete } from "@material-ui/lab";
import { SearchOutlined } from "@material-ui/icons";
import { firestoreAddBranch, firestoreUpdateBranch } from "../../store/actions/branch.action";
import BranchDialogDelete from "./dialog/branch_dialog_delete";
import { MODE_ADD, MODE_EDIT, MODE_SELECT, MODE_NONE } from "../../config/stringConfig";
import { MODEL_BRANCH } from "../../model/branch";
import BranchStaffTable from "./branch_staff_table";

class BranchDetail extends Component {
  // ====================== Inits ====================== //
  constructor() {
    super();
    this.state = {
      loading: false,
      mode: MODE_NONE, // 'add', 'select', 'update', 'none'
      branch: MODEL_BRANCH.model,
      error: MODEL_BRANCH.error,
      require: MODEL_BRANCH.require,
      regex: MODEL_BRANCH.regex,
      inputRegex: MODEL_BRANCH.inputRegex,
      errorMess: MODEL_BRANCH.errorMess,
      branchDialogDelete: false,
    };
  }

  // Chuyển state từ props
  static getDerivedStateFromProps(nextProps, prevState) {
    const oldMode = prevState.mode;
    const newMode = nextProps.mode;
    const oldId = prevState.branch.id;
    const newId = nextProps.branchId;
    if (oldMode !== newMode || oldId !== newId) {
      // Nếu có sự thay đổi về mode hoặc id thì update state
      const branchs = nextProps.branchs;
      const branch = branchs && branchs.find((item) => item.id === newId);
      return {
        mode: newMode,
        branch: branch || MODEL_BRANCH.model,
        error: MODEL_BRANCH.error,
      };
    }
    return null;
  }

  // ====================== Functions ====================== //

  selectUser = ({ value, reason }) => {
    if (reason === "select-option") {
      const { branch } = this.state;
      const staffs = (branch && branch.staffs) || [];
      staffs.push(value.id);
      this.setState({
        branch: {
          ...branch,
          staffs,
        },
        key: Math.random(),
      });
    }
  };

  deleteStaff = (value) => {
    const { branch } = this.state;
    const staffs = branch && branch.staffs;
    const newStaffs = staffs && staffs.filter((item) => item !== value.id);
    this.setState({
      branch: {
        ...branch,
        staffs: newStaffs,
      },
    });
  };

  // ====================== Hàm xử lý dialog ====================== //

  openBranchDelete = () => {
    this.setState({
      branchDialogDelete: true,
    });
  };
  closeBranchDelete = () => {
    this.setState({
      branchDialogDelete: false,
    });
  };
  confirmBranchDelete = () => {
    this.setState(
      {
        branchDialogDelete: false,
      },
      () => {
        this.props.onCancel();
      }
    );
  };

  // ====================== Hàm xử lý validation ====================== //

  handleValidate = ({ id, value }) => {
    // xử lý validate
    if (id === "id") {
      return;
    }
    const { require, regex, errorMess } = this.state;
    const condition = regex[id];
    const errorString = errorMess[id];
    // Xử lý validate
    if (value && value.length > 0) {
      if (condition && condition.test(value)) {
        // valid
        return false;
      } else {
        // lỗi
        return errorString;
      }
    } else {
      if (require[id]) {
        // require
        return "Please fill out this field";
      } else {
        // empty
        return false;
      }
    }
  };

  checkAllValid = () => {
    const { branch } = this.state;
    let error = {};
    let allValid = true;
    Object.entries(branch).forEach((data, index) => {
      const id = data[0];
      const value = data[1];
      if (id === "id") {
        return;
      }
      const isError = this.handleValidate({ id, value });
      error = {
        ...error,
        [id]: isError,
      };
      if (isError) {
        allValid = false;
      }
    });
    if (!allValid) {
      // Ko hợp lệ tất cả các field
      this.setState({
        error,
      });
    }
    return allValid;
  };

  // ====================== hàm khác ====================== //

  handleChange = (e) => {
    const { branch, error, inputRegex } = this.state;
    const id = e.target.id;
    const value = e.target.value;
    const inputCondition = inputRegex && inputRegex[id];
    if (!inputCondition || (inputCondition && inputCondition.test(value))) {
      this.setState({
        branch: {
          ...branch,
          [id]: value,
        },
        error: {
          ...error,
          [id]: this.handleValidate({ id, value }),
        },
      });
    }
  };

  handleChangePhone = (value) => {
    const { branch, error } = this.state;
    this.setState({
      branch: {
        ...branch,
        contactPhone: value,
      },
      error: {
        ...error,
        contactPhone: this.handleValidate({ id: "contactPhone", value }),
      },
    });
  };

  handleChangeLocation = (value) => {
    const { branch } = this.state;
    this.setState({
      branch: {
        ...branch,
        serviceLocation: value,
      },
    });
  };

  // ====================== Firebase Functions ====================== //

  handleAddOrUpdateBranch = () => {
    const { mode } = this.state;
    const allValid = this.checkAllValid();
    if (allValid) {
      // Hợp lệ
      if (mode === MODE_ADD) {
        this.hanldeAddBranch();
      } else if (mode === MODE_EDIT) {
        this.hanldeUpdateBranch();
      }
    }
  };

  hanldeAddBranch = () => {
    const { branch, error } = this.state;
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.firestoreAddBranch({ branch }, (response) => {
          this.setState(
            {
              loading: false,
            },
            () => {
              if (response) {
                // thành công
                this.props.onCancel();
              } else {
                this.setState({
                  // Xử lý lỗi trùng user name
                  error: {
                    ...error,
                    companyName: "This Company has been created. Please use other name.",
                  },
                });
              }
            }
          );
        });
      }
    );
  };

  hanldeUpdateBranch = () => {
    const { branch } = this.state;
    const { users } = this.props;
    const ids = users?.map((item) => item.id);
    const staffs = branch?.staffs?.filter((item) => ids?.includes(item));
    const data = {
      ...branch,
      staffs,
    };
    // console.log(data);
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.firestoreUpdateBranch({ branch: data }, () => {
          this.setState(
            {
              loading: false,
            },
            () => {
              this.props.onSelect();
            }
          );
        });
      }
    );
  };

  // ====================== Render Component ====================== //

  renderCancelButton = () => {
    const { loading, mode } = this.state;
    const disabled = mode === MODE_NONE || loading;
    return (
      <Button
        variant="contained"
        color="default"
        style={{ float: "left" }}
        disabled={disabled}
        onClick={this.props.onCancel}
      >
        Cancel
      </Button>
    );
  };

  renderSaveButton = () => {
    const { loading, mode } = this.state;
    const disabled = mode === MODE_NONE || loading;
    if (loading) {
      return (
        <Button
          variant="contained"
          color="default"
          disabled={disabled}
          style={{ float: "right" }}
          startIcon={<CircularProgress size={15} />}
        >
          Save
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        style={{
          backgroundColor: "green",
          float: "right",
          color: "#fff",
          opacity: disabled ? 0.2 : 1,
        }}
        onClick={this.handleAddOrUpdateBranch}
      >
        Save
      </Button>
    );
  };

  renderDeleteButton = () => {
    const { loading } = this.state;
    return (
      <Button
        variant="contained"
        color="secondary"
        style={{ backgroundColor: "red", float: "left" }}
        disabled={loading}
        onClick={this.openBranchDelete}
      >
        Delete
      </Button>
    );
  };

  renderUpdateButton = () => {
    const { loading } = this.state;
    if (loading) {
      return (
        <Button
          variant="contained"
          color="default"
          disabled
          style={{ float: "right" }}
          startIcon={<CircularProgress size={15} />}
        >
          Update
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color="primary"
        style={{ float: "right" }}
        onClick={this.handleAddOrUpdateBranch}
      >
        Update
      </Button>
    );
  };

  renderEditButton = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        style={{ float: "right" }}
        disableElevation
        onClick={() => this.props.onEdit()}
      >
        Edit
      </Button>
    );
  };

  renderButtonBar = () => {
    const { mode } = this.state;
    if (mode === MODE_SELECT) {
      return (
        <Grid item md={12} xs={12}>
          {this.renderEditButton()}
        </Grid>
      );
    }
    if (mode === MODE_EDIT) {
      return (
        <Grid item md={12} xs={12}>
          {this.renderDeleteButton()}
          {this.renderSaveButton()}
        </Grid>
      );
    }
    return (
      <Grid item md={12} xs={12}>
        {this.renderCancelButton()}
        {this.renderSaveButton()}
      </Grid>
    );
  };

  renderCompanyName = () => {
    const { branch, error, mode } = this.state;
    const companyName = branch && branch.companyName;
    const length = 150 - (companyName && companyName.length) || 0;
    const isError = error && error.companyName;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6} className="count-string">
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="companyName"
          label="Company Name"
          name="companyName"
          type="text"
          value={companyName}
          error={!!isError}
          helperText={isError}
          autoFocus
          disabled={disabled}
          onChange={this.handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderInitial = () => {
    const { branch, error, mode } = this.state;
    const initial = branch && branch.initial;
    const length = 32 - (initial && initial.length) || 0;
    const isError = error && error.initial;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6} className="count-string">
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="initial"
          label="Initial"
          name="initial"
          type="text"
          value={initial}
          error={!!isError}
          helperText={isError}
          autoFocus
          disabled={disabled}
          onChange={this.handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderContactEmail = () => {
    const { branch, error, mode } = this.state;
    const contactEmail = branch && branch.contactEmail;
    const isError = error && error.contactEmail;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="contactEmail"
          label="Contact Email"
          name="contactEmail"
          type="text"
          value={contactEmail}
          error={!!isError}
          helperText={isError}
          autoFocus
          disabled={disabled}
          onChange={this.handleChange}
        />
      </Grid>
    );
  };

  renderContactPhone = () => {
    const { branch, error, mode } = this.state;
    const contactPhone = branch && branch.contactPhone;
    const isError = error && error.contactPhone;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6}>
        <PhoneInput
          onlyCountries={["vn", "nz", "us", "au"]}
          country="nz"
          onChange={this.handleChangePhone}
          autoFormat={false}
          value={contactPhone}
          disabled={disabled}
          inputProps={{
            name: "contactPhone",
            required: true,
            autoFocus: true,
            label: "Contact Phone",
            id: "contactPhone",
            placeholder: "Phone number",
            margin: "normal",
          }}
          inputStyle={{
            width: "100%",
            borderColor: !!isError ? "red" : "rgba(0, 0, 0, 0.23)",
            color: disabled ? "#c8c8c8" : "#333",
          }}
        />
        <FormHelperText style={{ color: "red" }}>{isError}</FormHelperText>
      </Grid>
    );
  };

  renderServiceLocation = () => {
    const { branch, error, mode } = this.state;
    const serviceLocation = branch && branch.serviceLocation;
    const length = 300 - (serviceLocation && serviceLocation.length) || 0;
    const isError = error && error.serviceLocation;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={10}>
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="serviceLocation"
          label="Service Location"
          name="serviceLocation"
          type="text"
          value={serviceLocation}
          error={!!isError}
          helperText={isError}
          autoFocus
          disabled={disabled}
          onChange={this.handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderContactPosition = () => {
    const { branch, error, mode } = this.state;
    const position = branch && branch.position;
    const isError = error && error.companyName;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={2}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="position"
          label="Position"
          name="position"
          type="text"
          value={position || "0"}
          error={!!isError}
          helperText={isError}
          autoFocus
          disabled={disabled}
          onChange={this.handleChange}
        />
      </Grid>
    );
  };

  renderSearchAutocomplete = () => {
    const { branch, mode, key } = this.state;
    const { users } = this.props;
    const staffs = branch && branch.staffs;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    let options = users || [];
    return (
      <Grid item xs={6}>
        <Autocomplete
          id="asynchronous-search"
          onChange={(e, value, reason) => this.selectUser({ value, reason })}
          key={key}
          getOptionDisabled={(option) => staffs && staffs.includes(option.id)}
          getOptionLabel={(option) => {
            const firstName = option && option.firstName;
            const lastName = option.lastName;
            const fullName = firstName && lastName && `${firstName} ${lastName}`;
            return `${fullName} (${option.username})`;
          }}
          options={options}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label="Search By Name"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
    );
  };

  renderStaffStable = () => {
    const { branch, mode } = this.state;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={12}>
        <BranchStaffTable branch={branch} onDelete={this.deleteStaff} disabled={disabled} />
      </Grid>
    );
  };

  // ====================== Render Main ====================== //

  render() {
    const { branch, branchDialogDelete } = this.state;
    return (
      <Grid container spacing={2} direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12}>
          <h3 className="regular-title">Branch Details</h3>
        </Grid>
        {this.renderCompanyName()}
        {this.renderInitial()}
        {this.renderContactEmail()}
        {this.renderContactPhone()}
        {this.renderServiceLocation()}
        {this.renderContactPosition()}
        <Grid item xs={6}>
          <h3 className="regular-title">Staff List</h3>
        </Grid>
        {this.renderSearchAutocomplete()}
        {this.renderStaffStable()}
        {this.renderButtonBar()}
        <BranchDialogDelete
          dialogOpen={branchDialogDelete}
          handleClose={this.closeBranchDelete}
          handleConfirm={this.confirmBranchDelete}
          branch={branch}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  branchs: state.branchReducer.branchs,
  users: state.userReducer.users,
});

const mapDispatchToProps = {
  firestoreAddBranch,
  firestoreUpdateBranch,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BranchDetail);
