import firebase from "firebase";
import { LOGIN_LOADING, LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT_SUCCESS } from "../ref";

// -------------------------- LOGIN --------------------------
export const firebaseLogin = ({ email, password }) => (dispatch) => {
  firebaseLoading(dispatch);
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((user) => {
      firebaseLoginSuccess(dispatch, user, password);
    })
    .catch((error) => firebaseLoginFail(dispatch, error.message)); // Lỗi
};

const firebaseLoading = (dispatch) => {
  dispatch({
    type: LOGIN_LOADING,
  });
};

const firebaseLoginSuccess = (dispatch, user) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: user,
  });
};

const firebaseLoginFail = (dispatch, error) => {
  dispatch({
    type: LOGIN_FAILED,
    payload: error,
  });
};

// Logout
export const firebaseLogout = () => (dispatch) => {
  // const firebase = getFirebase();
  firebase
    .auth()
    .signOut()
    .then(() => firebaseLogoutSuccess(dispatch)); // Thành công
};

const firebaseLogoutSuccess = (dispatch) => {
  dispatch({
    type: LOGOUT_SUCCESS,
  });
};
