import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import moment from "moment";
import { Loading } from "../../component/loading";

class BranchTable extends Component {
  // ====================== Inits ====================== //
  constructor() {
    super();
    this.state = {
      edited: [],
      editError: [],
      header: [
        { id: "position", label: "Position", align: "center", sortable: false },
        { id: "companyName", label: "Company Name", align: "left", sortable: false },
        { id: "initial", label: "Initial", align: "left", sortable: false },
        { id: "phone", label: "Phone", align: "left", sortable: false },
        { id: "email", label: "Email", align: "left", sortable: false },
        { id: "createdOn", label: "Created on", align: "center", sortable: false },
      ],
      selectedOrder: null,
    };
  }

  // ====================== Functions ====================== //

  isSelected = (data) => {
    const { selected } = this.props;
    const exist = selected && selected.find((item) => item.itemCode === data.itemCode);
    return exist ? true : false;
  };

  isCheckedAll = () => {
    const { selected, reportData } = this.props;
    return selected && reportData && selected.length === reportData.length ? true : false;
  };

  // ====================== Data Functions ====================== //

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderTableHead = () => {
    const { branchs, orderBy, order } = this.props;
    const { header } = this.state;
    const disabled = !(branchs && branchs.length > 0);
    return (
      <TableHead>
        <TableRow>
          {header &&
            header.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                padding="default"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {this.renderTableSortLabel(headCell, disabled)}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  };

  renderTableSortLabel = (headCell, disabled) => {
    const { orderBy, order } = this.props;
    if (headCell.sortable) {
      return (
        <TableSortLabel
          disabled={disabled}
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : "asc"}
          onClick={() => this.props.handleSort(headCell.id)}
        >
          {headCell.label}
        </TableSortLabel>
      );
    }
    return headCell.label;
  };

  renderTableBody = () => {
    const { branchs, loading } = this.props;
    if (!loading) {
      return (
        <TableBody>
          {branchs && branchs.map((row, index) => this.renderTableRow({ row, index }))}
        </TableBody>
      );
    }
    return <TableBody />;
  };

  renderTableRow = ({ row, index }) => {
    const createAt = row && row.createAt;
    const seconds = createAt && createAt.seconds;
    const createOn = seconds && moment(createAt.toDate()).format("D MMM YYYY");
    return (
      <TableRow
        hover
        key={index}
        onClick={() => this.props.onRowClick(row)}
        style={{ cursor: "pointer" }}
      >
        <TableCell align="center">{row.position}</TableCell>
        <TableCell align="left">{row.companyName}</TableCell>
        <TableCell align="left">{row.initial}</TableCell>
        <TableCell align="left">{row.contactPhone}</TableCell>
        <TableCell align="left">{row.contactEmail}</TableCell>
        <TableCell align="center">{createOn}</TableCell>
      </TableRow>
    );
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    const { loading } = this.props;
    return (
      <Paper style={{ width: "100%", marginTop: 10, marginBottom: 30 }}>
        <TableContainer className="report-table">
          <Table stickyHeader aria-label="sticky table">
            {this.renderTableHead()}
            {this.renderTableBody()}
          </Table>
          <Loading loading={loading} />
        </TableContainer>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  branchs: state.branchReducer.branchs,
});

export default compose(connect(mapStateToProps, null))(BranchTable);
