import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import TimeTrackingEditCell from "./time_tracking_edit_cell";
import { TASK_ADD, TASK_UPDATE, TASK_DELETE } from "../../../store/ref";

class TimeTrackingEditRow extends Component {
  // ====================== Inits ====================== //

  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      edited: [],
    };
  }

  // ====================== Functions ====================== //

  // ====================== Data Functions ====================== //

  disableRow = () => {
    this.setState({
      disabled: true,
    });
  };

  enableRow = () => {
    this.setState({
      disabled: false,
    });
  };

  // ====================== Firebase Functions ====================== //

  getRowData = () => {
    const { row, tasks, range } = this.props;
    const filter = tasks.filter((item) => item.date === row.date && item.username === row.username);
    if (filter) {
      const data = new Array(range).fill(null).map((item, index) => {
        const check = row?.check[index];
        const task = filter.find((f) => f.index === index);
        return task || check;
      });
      return data;
    }
    return row;
  };

  // ====================== Render Component ====================== //

  renderInOut = () => {
    const { disabled } = this.state;
    const { row } = this.props;
    const data = this.getRowData();
    return data?.map((item, index) => {
      const timestamp = item?.timestamp;
      const time = timestamp ? moment(timestamp) : null;
      return (
        <TimeTrackingEditCell
          time={time}
          index={index}
          key={index}
          data={data}
          row={row}
          disabled={disabled}
          onError={this.disableRow}
          onValid={this.enableRow}
        />
      );
    });
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    const { row, index } = this.props;
    return (
      <React.Fragment>
        <TableRow hover aria-checked={false} tabIndex={-1} key={index}>
          <TableCell align="left">{row?.username}</TableCell>
          <TableCell align="left">{row?.fullName}</TableCell>
          <TableCell align="center">{row?.date}</TableCell>
          <TableCell align="center">{row?.day}</TableCell>
          {this.renderInOut()}
          <TableCell align="center" />
          <TableCell align="center" />
        </TableRow>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  tasks: state.timeTrackingReducer.tasks,
});

export default compose(connect(mapStateToProps, null))(TimeTrackingEditRow);
