import React from "react";
import SignInLink from "./sign_in_link";
import SignOutLink from "./sign_out_link";
import { connect } from "react-redux";

const Navbar = (props) => {
  const { auth, title, right } = props;
  const link = auth.uid ? <SignInLink title={title} right={right} /> : <SignOutLink />;
  return link;
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
});

export default connect(mapStateToProps, {})(Navbar);
