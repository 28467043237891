import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CssBaseline, Container, Grid, Button, IconButton, Tooltip } from "@material-ui/core";
import { PersonAdd, CloudDownloadOutlined, CloudUploadOutlined } from "@material-ui/icons";
import Navbar from "../00_Nav/nav_bar";
import RightNav from "../00_Nav/right_nav";
import { firestoreSnapShotUserList, unSnapShotUserList } from "../../store/actions/user.action";
import UserDetail from "./user_detail";
import UserTable from "./user_table";
import { exportUsersToCSV } from "./functions/export_user_csv";
import CsvUserDialogUpload from "./dialog/csv_user_dialog_upload";
import { MODE_ADD, MODE_EDIT, MODE_SELECT, MODE_NONE } from "../../config/stringConfig";

class UserManagement extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      error: "",
      loading: false,
      selectedUser: null,
      csvUserUpload: false,
      mode: MODE_NONE,
    };
  }

  componentDidMount = () => {
    this.props.firestoreSnapShotUserList(); // get users
  };

  componentWillUnmount = () => {
    this.props.unSnapShotUserList();
  };

  // ====================== Functions ====================== //

  onAdd = () => {
    this.setState({
      mode: MODE_ADD,
      selectedUser: null,
    });
  };

  onCancel = () => {
    this.setState({
      mode: MODE_NONE,
      selectedUser: null,
    });
  };

  onEdit = () => {
    this.setState({
      mode: MODE_EDIT,
    });
  };

  onSelect = () => {
    this.setState({
      mode: MODE_SELECT,
    });
  };

  onRowClick = (data) => {
    this.setState({
      selectedUser: data.id,
      mode: MODE_SELECT,
    });
  };

  handleSelect = (e, id) => {
    this.setState({
      [id]: e.target.value,
    });
  };

  openCsvUserUpload = () => {
    this.setState({
      csvUserUpload: true,
    });
  };

  closeCsvUserUpload = () => {
    this.setState({
      csvUserUpload: false,
    });
  };

  confirmCsvUserUpload = (files) => {
    this.setState({
      csvUserUpload: false,
    });
  };

  // ====================== Firebase Functions ====================== //

  // ====================== Các hàm render ====================== //

  renderSectionUserTop = () => {
    const { selectedUser, mode } = this.state;
    return (
      <Grid container spacing={2} direction="row">
        <Grid item md={6} xs={12} className="padding-grid">
          <h3 className="regular-title">User Details</h3>
          <UserDetail
            userId={selectedUser}
            mode={mode}
            onCancel={this.onCancel}
            onEdit={this.onEdit}
            onSelect={this.onSelect}
          />
        </Grid>
      </Grid>
    );
  };

  renderSectionUserBottom = () => {
    const { users } = this.props;
    const data = users
      ? users.map((item) => {
          const firstName = item.firstName;
          const lastName = item.lastName;
          const fullName = `${firstName} ${lastName}`;
          return {
            ...item,
            fullName,
          };
        })
      : [];
    const totalUser = data ? data.length : 0;
    return (
      <Grid
        container
        spacing={2}
        className="section-user-bottom-container"
        justify="flex-end"
        alignItems="flex-end"
      >
        <Grid item md={4} xs={12}>
          <Grid container spacing={1} justify="flex-end" alignItems="flex-end">
            {this.renderExportImportUser()}
            <Grid item>
              <div className="total-user">Total User: {totalUser}</div>
            </Grid>
            <Grid item>
              <Button
                className="add-btn"
                variant="outlined"
                color="primary"
                startIcon={<PersonAdd />}
                onClick={this.onAdd}
              >
                Add User
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <UserTable onRowClick={this.onRowClick} />
        </Grid>
      </Grid>
    );
  };

  renderExportImportUser = () => {
    const { users } = this.props;
    const disabled = !users || (users && users.length <= 0);
    return (
      <Grid item>
        <Tooltip title="Export all users to csv">
          <span>
            <IconButton
              aria-label="download"
              onClick={() => exportUsersToCSV({ users })}
              disabled={disabled}
            >
              <CloudDownloadOutlined style={{ color: disabled ? "#c8c8c8" : "#333" }} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Import  all users from csv">
          <span>
            <IconButton aria-label="upload" onClick={() => this.openCsvUserUpload()}>
              <CloudUploadOutlined style={{ color: "#333" }} />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    );
  };

  // ====================== Main ====================== //
  render() {
    const { auth } = this.props;
    const { csvUserUpload } = this.state;
    if (!auth.uid) return <Redirect to={"/signin"} />;
    return (
      <div className="root-container">
        <CssBaseline />
        <Navbar title="User Management" right={<RightNav />} />
        <Container maxWidth="xl">
          {this.renderSectionUserTop()}
          {this.renderSectionUserBottom()}
        </Container>
        <CsvUserDialogUpload
          dialogOpen={csvUserUpload}
          handleClose={this.closeCsvUserUpload}
          handleConfirm={this.confirmCsvUserUpload}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  users: state.userReducer.users,
});

const mapDispatchToProps = {
  firestoreSnapShotUserList,
  unSnapShotUserList,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(UserManagement);
