import { USER_LIST_SUCCESS } from "../ref";

const initState = {
  users: null,
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case USER_LIST_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
