import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { FormControlLabel, Checkbox } from "@material-ui/core";

class TimeTrackingCheckBox extends Component {
  // ====================== Inits ====================== //

  // ====================== Functions ====================== //

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  // ====================== Render Main ====================== //

  render() {
    const { photo, location, disabled } = this.props;
    return (
      <React.Fragment>
        <FormControlLabel
          control={
            <Checkbox
              checked={photo}
              onChange={() => this.props.onCheckPhoto()}
              name="photo"
              color="primary"
              disabled={disabled}
            />
          }
          label="Show photos"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={location}
              onChange={() => this.props.onCheckLocation()}
              name="location"
              color="primary"
              disabled={disabled}
            />
          }
          label="Show location"
        />
      </React.Fragment>
    );
  }
}

// const mapStateToProps = (state) => ({
//   branchs: state.branchReducer.branchs,
// });

// const mapDispatchToProps = {};

export default compose(connect(null, null))(TimeTrackingCheckBox);
