// local
export const LOCAL_USER = "Local User";
// Task
export const TASK_ADD = "add";
export const TASK_UPDATE = "update";
export const TASK_DELETE = "delete";
// firebase ref
export const REF_USER = "user";
export const REF_BRANCH = "branch";
export const REF_REQUEST = "request";
export const REF_ROSTER = "roster";
export const REF_TIME_TRACKING = "checkInOut";
export const REF_LOCK_ROSTER = "lockRoster";
// redux ref
export const LOGIN_LOADING = "firebase_login_loading";
export const LOGIN_SUCCESS = "firebase_login_success";
export const LOGIN_FAILED = "firebase_login_fail";
export const LOGOUT_SUCCESS = "firebase_logout_success";

export const USER_LIST_SUCCESS = "get_user_list_success";
export const BRANCH_LIST_SUCCESS = "get_branch_list_success";
export const REQUEST_LIST_SUCCESS = "get_request_list_success";
export const REQUEST_LIST_PENDING_SUCCESS = "get_request_list_pending_success";
export const ROSTER_LIST_SUCCESS = "get_roster_list_success";
export const LOCK_ROSTER_LIST_SUCCESS = "get_lock_roster_list_success";
export const TIME_TRACKING_LIST_SUCCESS = "get_time_tracking_list_success";
export const TIME_TRACKING_TASK_SUCCESS = "set_time_tracking_task_success";
export const TIME_TRACKING_TASK_ERROR = "set_time_tracking_task_error";
export const TIME_TRACKING_RESET_TASK = "reset_task_success";
