import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { AccessTime } from "@material-ui/icons";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import IOSSwitch from "../../../component/iosSwitch";
import { firestoreRosterWeekUpdate } from "../../../store/actions/roster.action";

class RosterDialogWeekEdit extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    // state
    this.state = {
      rosterList: null,
      percent: "",
    };
  }

  resetData = () => {
    const { rosters, staff, dates, branchs, branch } = this.props;
    const staffId = staff.id;
    const filtered = rosters?.filter(
      (item) => item.staff === staffId && dates?.includes(item.date)
    ); // // Lấy danh sách branch
    const branchList = branchs?.filter((item) => item.staffs?.includes(staffId));
    const rosterList = dates?.map((date) => {
      // default data
      const defaultFrom = moment(date, "ddd DD MMM YYYY").hour(9).minute(0).second(0);
      const defaultTo = moment(date, "ddd DD MMM YYYY").hour(18).minute(0).second(0);
      const roster = filtered?.find((item) => item.date === date);
      // Work At
      const workAt = roster?.workAt;
      const wSeconds = workAt?.seconds;
      const workDate = wSeconds ? moment(workAt.toDate()) : defaultFrom;
      // End At
      const endAt = roster?.endAt;
      const eSeconds = endAt?.seconds;
      const endDate = eSeconds ? moment(endAt.toDate()) : defaultTo;
      // Lấy branch ưu tiên
      const branchId = roster?.branch;
      const thisBranch = branchId ? branchId : branch.id;
      // Lấy off
      const off = roster?.off || false;
      return {
        ...roster,
        workAt: workDate,
        endAt: endDate,
        branch: thisBranch,
        staff: staffId,
        off,
        date,
      };
    });

    this.setState({
      rosterList,
      branchList,
    });
  };

  // ====================== Functions ====================== //

  handleFromChange = ({ value, item }) => {
    const { rosterList } = this.state;
    const date = item.date;
    const string = moment(value).format("hh:mm A");
    const workAt = moment(`${date} ${string}`, "ddd DD MMM YYYY hh:mm A");
    const newList = rosterList?.map((roster) => {
      if (roster.date === date) {
        return {
          ...item,
          workAt,
        };
      }
      return roster;
    });
    this.setState({
      rosterList: newList,
    });
  };

  handleToChange = ({ value, item }) => {
    const { rosterList } = this.state;
    const date = item.date;
    const string = moment(value).format("hh:mm A");
    const endAt = moment(`${date} ${string}`, "ddd DD MMM YYYY hh:mm A");
    const newList = rosterList?.map((roster) => {
      if (roster.date === date) {
        return {
          ...item,
          endAt,
        };
      }
      return roster;
    });
    this.setState({
      rosterList: newList,
    });
  };

  handleSelectBranch = ({ event, item }) => {
    const { rosterList } = this.state;
    const date = item.date;
    const newList = rosterList?.map((roster) => {
      if (roster.date === date) {
        return {
          ...item,
          branch: event.target.value,
        };
      }
      return roster;
    });
    this.setState({
      rosterList: newList,
    });
  };

  toggleSwitch = ({ item }) => {
    const { rosterList } = this.state;
    const off = item.off;
    const date = item.date;
    const newList = rosterList?.map((roster) => {
      if (roster.date === date) {
        return {
          ...item,
          off: !off,
        };
      }
      return roster;
    });
    this.setState({
      rosterList: newList,
    });
  };

  // ====================== Firebase Functions ====================== //

  updateRoster = () => {
    const { rosterList } = this.state;
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.firestoreRosterWeekUpdate({ rosters: rosterList }, () => {
          this.setState(
            {
              loading: false,
              percent: "",
            },
            () => {
              this.props.handleClose();
            }
          );
        });
      }
    );
  };

  // ====================== Render Component ====================== //

  renderWeekRoster = () => {
    const { rosterList } = this.state;
    return rosterList
      ? rosterList.map((item, index) => this.renderWorkTime({ item, index }))
      : null;
  };

  renderWorkTime = ({ item, index }) => {
    const off = item?.off;
    const date = item?.date;
    return (
      <Grid
        container
        spacing={1}
        direction="row"
        key={index}
        className="roster-work-time"
        style={{ borderColor: off ? "#fff" : "#c8c8c8" }}
      >
        <Grid item xs={12} style={{ opacity: off ? 0.2 : 1 }}>
          <h3 style={{ color: "#1976d2" }}>{date}</h3>
        </Grid>
        <Grid item md={3} xs={6} style={{ opacity: off ? 0.2 : 1 }}>
          {this.renderFromPicker({ item })}
        </Grid>
        <Grid item md={3} xs={6} style={{ opacity: off ? 0.2 : 1 }}>
          {this.renderToPicker({ item })}
        </Grid>
        <Grid item md={4} xs={9} style={{ opacity: off ? 0.2 : 1, paddingTop: 12 }}>
          {this.renderBranchPicker({ item })}
        </Grid>
        <Grid item md={2} xs={3}>
          {this.renderOff({ item })}
        </Grid>
      </Grid>
    );
  };

  renderFromPicker = ({ item }) => {
    const workAt = item?.workAt;
    const off = item?.off;
    return (
      <FormControl fullWidth>
        <InputLabel htmlFor="time-picker-scroll" className="time-picker-custom-input-label">
          From
        </InputLabel>
        <TimePicker
          picker="time"
          id="time-picker-scroll"
          showSecond={false}
          value={workAt}
          disabled={off}
          className="time-picker-scroll"
          format="hh:mm A"
          onChange={(value) => this.handleFromChange({ value, item })}
          use12Hours
          inputReadOnly
          inputIcon={<AccessTime style={{ color: "rgba(0, 0, 0, 0.54)" }} />}
          clearIcon={null}
          minuteStep={5}
        />
      </FormControl>
    );
  };

  renderToPicker = ({ item }) => {
    const endAt = item?.endAt;
    const off = item?.off;
    return (
      <FormControl fullWidth>
        <InputLabel htmlFor="time-picker-scroll" className="time-picker-custom-input-label">
          To
        </InputLabel>
        <TimePicker
          picker="time"
          id="time-picker-scroll"
          showSecond={false}
          value={endAt}
          disabled={off}
          className="time-picker-scroll"
          format="hh:mm A"
          onChange={(value) => this.handleToChange({ value, item })}
          use12Hours
          inputReadOnly
          inputIcon={<AccessTime style={{ color: "rgba(0, 0, 0, 0.54)" }} />}
          clearIcon={null}
          minuteStep={5}
        />
      </FormControl>
    );
  };

  renderBranchPicker = ({ item }) => {
    const { branchList } = this.state;
    const branch = item?.branch;
    const options = branchList?.map((item, index) => {
      const value = item?.id;
      const companyName = item?.companyName;
      return (
        <option value={value} key={index}>
          {companyName}
        </option>
      );
    });
    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="outlined-age-native-simple">Select Branch</InputLabel>
        <Select
          native
          value={branch}
          onChange={(event) => this.handleSelectBranch({ event, item })}
          margin="dense"
          label="Age"
          inputProps={{
            name: "age",
            id: "outlined-age-native-simple",
          }}
        >
          {options}
        </Select>
      </FormControl>
    );
  };

  renderOff = ({ item }) => {
    const off = item?.off;
    return (
      <FormControlLabel
        style={{ alignSelf: "flex-end", float: "right", marginTop: 6, marginRight: 0 }}
        control={
          <IOSSwitch checked={off} onChange={() => this.toggleSwitch({ item })} name="off" />
        }
        label="Off"
      />
    );
  };

  renderSaveButton = () => {
    const { loading, percent } = this.state;
    if (loading) {
      return (
        <Button
          variant="contained"
          color="default"
          disabled
          style={{ float: "right", width: 180 }}
          disableElevation
          startIcon={<CircularProgress size={15} />}
        >
          Updating {percent} ...
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color="primary"
        style={{ backgroundColor: "green", float: "right", width: 180 }}
        disableElevation
        onClick={() => this.updateRoster()}
      >
        OK
      </Button>
    );
  };

  // ====================== Render Main ====================== //

  render() {
    const { loading } = this.state;
    const { dialogOpen, handleClose, staff } = this.props;
    const firstName = staff && staff.firstName;
    const lastName = staff && staff.lastName;
    const fullName = `${firstName} ${lastName}`;
    return (
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        onEntering={this.resetData}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="sm"
        disableBackdropClick
      >
        <DialogTitle id="scroll-dialog-title">{fullName}</DialogTitle>
        <DialogContent dividers className="container-csv-upload one-file">
          {this.renderWeekRoster()}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            disabled={loading}
            variant="outlined"
            style={{ float: "right", width: 180 }}
          >
            Cancel
          </Button>
          {this.renderSaveButton()}
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  rosters: state.rosterReducer.rosters,
  users: state.userReducer.users,
  branchs: state.branchReducer.branchs,
});

const mapDispatchToProps = {
  firestoreRosterWeekUpdate,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(RosterDialogWeekEdit);
