import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import SignInScene from "../01_Login/login";
import UserManagementScene from "../02_Users/user_management";
import BranchManagementScene from "../03_Branch/branch_management";
import RosterManagementScene from "../04_Roster/roster_management";
import RequestScreen from "../05_Request/request";
import ReportManagementScene from "../06_Report_Management/report_management";
import ReportTimeTrackingScene from "../07_Report_Time_Tracking/time_tracking_management";

import { URL } from "../../config/config";

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path={URL.home} component={UserManagementScene} exact />
          <Route path={URL.signin} component={SignInScene} />
          <Route path={URL.branch} component={BranchManagementScene} />
          <Route path={URL.roster} component={RosterManagementScene} />
          <Route path={URL.request} component={RequestScreen} />
          <Route path={URL.reportManagement} component={ReportManagementScene} />
          <Route path={URL.reportTimeTracking} component={ReportTimeTrackingScene} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default connect(null, null)(Router);
