import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { TextField, InputAdornment } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

class TimeTrackingSearch extends Component {
  // ====================== Inits ====================== //

  // ====================== Functions ====================== //

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  // ====================== Render Main ====================== //

  render() {
    const { users, disabled } = this.props;
    return (
      <Autocomplete
        id="search"
        onChange={(e, value) => this.props.selectUser(value)}
        // value={selectedUser}
        getOptionSelected={(option) => option}
        getOptionDisabled={(option) => !option.active}
        getOptionLabel={(option) => {
          return `${option?.firstName} ${option?.lastName} (${option.username})`;
        }}
        options={users || []}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="dense"
            label="Search By Name"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.userReducer.users,
});

// const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, null))(TimeTrackingSearch);
