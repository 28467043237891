import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CssBaseline,
  Container,
  Grid,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  Button,
  IconButton,
  ButtonGroup,
} from "@material-ui/core";
import { CheckCircleOutline, BlockOutlined, BallotOutlined, HelpOutline } from "@material-ui/icons";
import moment from "moment";
import { Redirect } from "react-router-dom";
import {
  REQUEST_ALL,
  REQUEST_REJECTED,
  REQUEST_ACCEPTED,
  REQUEST_SENT,
} from "../../config/stringConfig";
import Navbar from "../00_Nav/nav_bar";
import RightNav from "../00_Nav/right_nav";
import { Loading } from "../../component/loading";
import { firestoreSnapShotUserList, unSnapShotUserList } from "../../store/actions/user.action";
import {
  firestoreSnapshotRequest,
  unSubRequest,
  firestoreUpdateRequest,
} from "../../store/actions/request.action";

class RequestScreen extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      loading: false,
      status: REQUEST_ALL,
    };
  }

  componentDidMount = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.firestoreSnapShotUserList();
        this.props.firestoreSnapshotRequest(() => {
          this.setState(
            {
              loading: false,
            },
            () => this.refineData()
          );
        });
      }
    );
  };

  componentWillUnmount = () => {
    this.props.unSubRequest();
    this.props.unSnapShotUserList();
  };

  refineData = () => {
    const { status } = this.state;
    const { requests } = this.props;
    let data = requests;
    if (status !== REQUEST_ALL) {
      data = requests.filter((item) => item.status === status);
    }
    this.setState({
      data,
    });
  };

  // -------------------------- FUNCTIONS --------------------------

  handleChangeStatus = ({ value }) => {
    const { status } = this.state;
    if (value === status) {
      return;
    }
    this.setState(
      {
        status: value,
      },
      () => this.refineData()
    );
  };

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  updateStatus = ({ item, status }) => {
    const request = {
      ...item,
      status,
    };
    this.props.firestoreUpdateRequest({ request });
  };

  // -------------------------- RENDER --------------------------

  renderFilterBar = () => {
    const { status } = this.state;
    return (
      <Grid container spacing={1} direction="row" justify="flex-end" alignItems="center">
        <Grid item>{status}</Grid>
        <Grid item>
          <ButtonGroup aria-label="outlined primary button group" style={{ marginTop: 5 }}>
            <Button
              className={`filter-button ${status === REQUEST_ALL ? "active" : ""}`}
              onClick={() => this.handleChangeStatus({ value: REQUEST_ALL })}
            >
              <BallotOutlined />
            </Button>
            <Button
              className={`filter-button ${status === REQUEST_SENT ? "active" : ""}`}
              onClick={() => this.handleChangeStatus({ value: REQUEST_SENT })}
            >
              <HelpOutline />
            </Button>
            <Button
              className={`filter-button ${status === REQUEST_ACCEPTED ? "active" : ""}`}
              onClick={() => this.handleChangeStatus({ value: REQUEST_ACCEPTED })}
            >
              <CheckCircleOutline />
            </Button>
            <Button
              className={`filter-button ${status === REQUEST_REJECTED ? "active" : ""}`}
              onClick={() => this.handleChangeStatus({ value: REQUEST_REJECTED })}
            >
              <BlockOutlined />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };

  renderTable = () => {
    return (
      <Paper style={{ marginBottom: 20 }}>
        <TableContainer className="report-table">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Created At</TableCell>
                <TableCell>Request on</TableCell>
                <TableCell>Staff</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell>Note</TableCell>
                <TableCell align="center" style={{ minWidth: 100 }}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            {this.renderTableBody()}
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  renderTableBody = () => {
    const { loading, data } = this.state;
    if (!loading) {
      return (
        <TableBody>{data?.map((item, index) => this.renderTableRow({ item, index }))}</TableBody>
      );
    }
    return <Loading loading={true} />;
  };

  renderTableRow = ({ item, index }) => {
    const { users } = this.props;
    const onDate = item?.onDate;
    const dateString = onDate && onDate.seconds && moment(onDate.toDate()).format("ddd DD/MM/YYYY");
    const createAt = item?.createAt;
    const timeStamp =
      createAt && createAt.seconds && moment(createAt.toDate()).format("ddd DD/MM/YYYY hh:mm:ss A");
    const type = item?.type;
    const note = item?.note;
    const staff = item?.staff;
    const user = users?.find((s) => s.id === staff);
    const fullName = `${user?.firstName} ${user?.lastName}`;
    return (
      <TableRow hover key={index}>
        <TableCell align="left">{timeStamp}</TableCell>
        <TableCell align="left">{dateString}</TableCell>
        <TableCell align="left">{fullName}</TableCell>
        <TableCell align="center">{type}</TableCell>
        <TableCell align="left">{note}</TableCell>
        {this.renderStatusButtonBar({ item })}
      </TableRow>
    );
  };

  renderStatusButtonBar = ({ item }) => {
    const status = item?.status;
    return (
      <TableCell align="center" style={{ minWidth: 100 }}>
        <IconButton
          aria-label="deny"
          onClick={() => this.updateStatus({ item, status: REQUEST_REJECTED })}
          style={{
            padding: 5,
            marginHorizontal: 10,
            opacity: status !== REQUEST_REJECTED ? 0.5 : 1,
          }}
        >
          <BlockOutlined style={{ color: status !== REQUEST_REJECTED ? "#ccc" : "red" }} />
        </IconButton>
        <IconButton
          aria-label="accept"
          onClick={() => this.updateStatus({ item, status: REQUEST_ACCEPTED })}
          style={{
            padding: 5,
            marginHorizontal: 10,
            opacity: status !== REQUEST_ACCEPTED ? 0.5 : 1,
          }}
        >
          <CheckCircleOutline style={{ color: status !== REQUEST_ACCEPTED ? "#ccc" : "green" }} />
        </IconButton>
      </TableCell>
    );
  };

  // -------------------------- MAIN --------------------------

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to={"/signin"} />;
    return (
      <div className="root-container">
        <CssBaseline />
        <Navbar title="Request" right={<RightNav />} />
        <Container maxWidth="xl">
          <Grid container spacing={2} direction="row">
            <Grid item md={12} xs={12}>
              {this.renderFilterBar()}
            </Grid>
            <Grid item md={12} xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
        </Container>
              
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  requests: state.requestReducer.requests,
  users: state.userReducer.users,
});

const mapDispatchToProps = {
  firestoreSnapShotUserList,
  unSnapShotUserList,
  firestoreSnapshotRequest,
  unSubRequest,
  firestoreUpdateRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestScreen);
