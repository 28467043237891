import { REQUEST_LIST_SUCCESS, REQUEST_LIST_PENDING_SUCCESS } from "../ref";

const initState = {
  requests: null,
  pendingRequest: null,
};

const requestReducer = (state = initState, action) => {
  switch (action.type) {
    case REQUEST_LIST_SUCCESS:
      return {
        ...state,
        requests: action.payload,
      };
    case REQUEST_LIST_PENDING_SUCCESS:
      return {
        ...state,
        pendingRequest: action.payload,
      };
    default:
      return state;
  }
};

export default requestReducer;
