import FileSaver from "file-saver";
import moment from "moment";
import { getHeaderByRange } from "../../../util/refine.timetracking";

export const exportToCSV = ({ reportData, branch, branchs, from, to, totalWorking, range }) => {
  // =========================  Provider =========================
  const selectedBranch = branchs?.find((b) => b.id === branch);
  const branchName = selectedBranch && selectedBranch.companyName;
  const header = getHeaderByRange(range);
  const body = reportData;
  const length = header.length - 1; // Bỏ breaking
  const time = moment().format("DD/MM/YYYY HH:mm:ss");
  const fromString = moment(from).format("DD/MM/YYYY");
  const toString = moment(to).format("DD/MM/YYYY");
  const total = secondsToHoursFixed({ seconds: totalWorking });
  // =========================  Row Data =========================
  const rowBranch = rowLeftData({ data: branchName, length });
  const rowEmpty = rowEmptyData({ length });
  const rowTitle = rowLeftData({ data: "Timesheet Details", length });
  const rowCreateOn = rowLeftData({ data: `Created on:${time}`, length });
  const rowCreateBy = rowLeftData({ data: "Created by:Admin", length });
  const rowPeriod = rowLeftData({ data: `Period: ${fromString} - ${toString}`, length });
  const rowTotal = rowTotalData({ data: total, length });
  const rowTableHeader = rowHeaderData({ data: header });
  const rowTableBody = rowBodyData({ data: body, range, length });
  // =========================  Section =========================
  const sectionTop = `${rowBranch}${rowEmpty}${rowEmpty}${rowTitle}${rowCreateOn}${rowCreateBy}${rowPeriod}${rowEmpty}${rowTotal}${rowEmpty}`;
  const sectionTable = `${rowTableHeader}${rowTableBody}`;
  // // =========================  CSV =========================
  const csvBuffer = `${sectionTop}${sectionTable}`;
  const fromName = moment(from).format("DD");
  const toName = moment(to).format("DDMMYY");
  const currentTime = moment().format("HH:mm");
  const fileName = `${branchName} - Timesheet Details - ${fromName}-${toName}-${currentTime}`;
  const fileType = "text/csv;charset=utf-8;";
  const fileExtension = ".csv";
  const data = new Blob([csvBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

const rowLeftData = ({ data, length }) => {
  let rowString = data;
  for (let i = 0; i < length - 1; i++) {
    rowString = `${rowString},`;
  }
  rowString = `${rowString}\n`;
  return rowString;
};

const rowTotalData = ({ data, length }) => {
  let rowString = "";
  for (let i = 0; i < length - 3; i++) {
    rowString = `${rowString},`;
  }
  rowString = `${rowString},Total,${data},\n`;
  return rowString;
};

const rowEmptyData = ({ length }) => {
  return `${new Array(length).fill("").join(",")},\n`;
};

const rowHeaderData = ({ data }) => {
  const filter = data && data.filter((d) => d.csvLabel !== "Breaking Time"); // bỏ breacking time
  let rowString = filter && filter.map((d) => d.csvLabel);
  rowString = `${rowString},Notes\n`;
  return rowString;
};

const rowBodyData = ({ data, range, length }) => {
  const bodyArr = data?.map((d) => {
    const rows = d?.result?.map((item) => {
      const username = item?.username;
      const fullName = item?.fullName;
      const day = item?.day;
      const date = item?.date;
      const seconds = item?.workSeconds;
      const working = secondsToHoursFixed({ seconds });
      const check = new Array(range).fill("").map((c, index) => {
        return item?.check[index]?.time || c;
      });
      const inOut = check?.join(",");
      return `${username},${fullName},${date},${day},${inOut},${working},`;
    });
    const rowString = rows?.join("\n");
    const sumWork = secondsToHoursFixed({ seconds: d?.sumWorking });
    const rowTotal = `${new Array(length - 1).fill("").join(",")},${sumWork},\n`;
    const rowMerge = `${rowString},\n${rowTotal}`;
    return rowMerge;
  });
  return bodyArr.join("\n");
};

// Chuyến giây sang giờ phút
const secondsToHoursFixed = ({ seconds }) => {
  if (seconds && seconds >= 0) {
    const hours = (seconds / 3600).toFixed(2);
    return hours;
  }
  return 0.0;
};
