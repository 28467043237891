import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import loginReducer from "./login.reducer";
import userReducer from "./user.reducer";
import branchReducer from "./branch.reducer";
import rosterReducer from "./roster.reducer";
import requestReducer from "./request.reducer";
import timeTrackingReducer from "./time_tracking.reducer";

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  loginReducer,
  userReducer,
  branchReducer,
  rosterReducer,
  requestReducer,
  timeTrackingReducer,
});

export default rootReducer;
