import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

class TimeTrackingBranch extends Component {
  // ====================== Inits ====================== //

  // ====================== Functions ====================== //

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  // ====================== Render Main ====================== //

  render() {
    const { branchs, branch, disabled } = this.props;
    return (
      <FormControl variant="outlined" margin="dense" fullWidth className="table-select">
        <InputLabel id="branchLabel">Branch</InputLabel>
        <Select
          labelId="branchLabel"
          id="branch"
          name="branch"
          value={branch}
          onChange={this.props.handleSelect}
          label="Branch"
          disabled={disabled}
        >
          {branchs?.map((item, index) => (
            <MenuItem value={item?.id} key={index}>
              {item?.companyName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

const mapStateToProps = (state) => ({
  branchs: state.branchReducer.branchs,
});

// const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, null))(TimeTrackingBranch);
