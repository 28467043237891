import FileSaver from 'file-saver';
import moment from 'moment';
import { BRAND_NAME } from '../../../config/config';
import { jsonToCSV } from 'react-papaparse';

const columns = [
    'username',
    'pincode',
    'firstName',
    'lastName',
    'phone',
    'email',
    'dob',
    'active',
    'level',
];

export const exportUsersToCSV = ({ users}) => {
    // =========================  Provider =========================
    // // =========================  Row Data =========================
    const rowTableHeader = 'Username,Pincode,FirstName,LastName,Phone,Email,DOB,Active,Level\n';
    const bodyData = users;
    const rowTableBody = jsonToCSV(bodyData, { columns: columns, header: false, quotes: true });
    // // =========================  CSV =========================
    const csvBuffer = `${rowTableHeader}${rowTableBody}`;
    const currentTime = moment().format('DD_MM_YYYY-HH_mm_ss');
    const fileName = `[${BRAND_NAME}] All User - ${currentTime}`;
    const fileType = 'text/csv;charset=utf-8;';
    const fileExtension = '.csv';
    const data = new Blob([csvBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}