import { BRANCH_LIST_SUCCESS } from "../ref";

const initState = {
  branchs: null,
};

const branchReducer = (state = initState, action) => {
  switch (action.type) {
    case BRANCH_LIST_SUCCESS:
      return {
        ...state,
        branchs: action.payload,
      };
    default:
      return state;
  }
};

export default branchReducer;
