import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CssBaseline, Container, Grid, Button, CircularProgress } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import lodash from "lodash";
import dateArray from "moment-array-dates";
import { Loading } from "../../component/loading";
import Navbar from "../00_Nav/nav_bar";
import RightNav from "../00_Nav/right_nav";
import { firestoreSnapShotUserList, unSnapShotUserList } from "../../store/actions/user.action";
import {
  firestoreSnapShotBranchList,
  unSnapShotBranchList,
} from "../../store/actions/branch.action";
import {
  firestoreSnapShotRosters,
  unSnapShotRosters,
  firestoreSnapShotLockRoster,
  unSnapShotLockRoster,
  firestoreLockRoster,
  firestoreUnLockRoster,
  firestoreCopyRostersLastWeek,
  firestoreDeleteRostersThisWeek,
} from "../../store/actions/roster.action";
import RosterTable from "./roster_table";

class RosterManagement extends Component {
  // ====================== Inits ====================== //

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      copying: false,
      error: false,
      from: moment().startOf("isoWeek"),
      to: moment().endOf("isoWeek"),
      dates: dateArray.range(
        moment().startOf("isoWeek"),
        moment().endOf("isoWeek"),
        "ddd DD MMM YYYY",
        true
      ),
      percent: "",
    };
  }

  componentDidMount = () => {
    this.props.firestoreSnapShotUserList(); // snapshot users
    this.props.firestoreSnapShotBranchList(() => {
      this.isFilledUp();
    }); // snapshot branchs
    this.getSnapShotWithClearRosterFromTo(); // snapshot roster + xóa snapshot cũ
  };

  componentWillUnmount = () => {
    this.props.unSnapShotUserList();
    this.props.unSnapShotBranchList();
    this.props.unSnapShotRosters();
    this.props.unSnapShotLockRoster();
  };

  // ====================== Functions ====================== //

  handleWeekChange = (date) => {
    const from = moment(date).startOf("isoWeek");
    const to = moment(date).endOf("isoWeek");
    const dates = dateArray.range(from, to, "ddd DD MMM YYYY", true);
    this.setState(
      {
        from,
        to,
        dates,
      },
      () => {
        this.getSnapShotWithClearRosterFromTo();
      }
    );
  };

  formatWeekSelectLabel = (date, invalidLabel) => {
    const from = moment(date).startOf("isoWeek").format("ddd DD MMM YYYY");
    const to = moment(date).endOf("isoWeek").format("ddd DD MMM YYYY");
    const label = `${from} - ${to}`;
    return date ? label : invalidLabel;
  };

  isFilledUp = () => {
    const { branchs, rosters } = this.props;
    if (branchs && rosters) {
      const byBranchs = lodash.groupBy(rosters, "branch");
      const size = byBranchs && Object.keys(byBranchs)?.length;
      const filledUp = branchs && branchs?.length === size;
      this.setState({
        filledUp,
      });
    }
  };

  // ====================== Firebase Functions ====================== //

  getSnapShotWithClearRosterFromTo = () => {
    const { from, to } = this.state;
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.unSnapShotLockRoster();
        this.props.unSnapShotRosters();
        setTimeout(() => {
          this.props.firestoreSnapShotLockRoster({ from, to });
          this.props.firestoreSnapShotRosters({ from, to }, () => {
            this.setState(
              {
                loading: false,
              },
              () => this.isFilledUp()
            );
          });
        }, 200);
      }
    );
  };

  copyRosterLastWeek = () => {
    const { from, to } = this.state;
    this.setState(
      {
        copying: true,
      },
      () => {
        this.props.firestoreDeleteRostersThisWeek({ from, to });
        this.props.firestoreLockRoster({ from, to });
        this.props.firestoreCopyRostersLastWeek({ from, to }, () => {
          this.setState({
            copying: false,
          });
        });
      }
    );
  };

  // ====================== Render Component ====================== //

  renderPicker = () => {
    const { from, loading } = this.state;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          autoOk
          className="date-picker"
          variant="inline"
          inputVariant="outlined"
          fullWidth
          value={from}
          label="Select Week"
          disabled={loading}
          InputAdornmentProps={{ position: "end" }}
          onChange={this.handleWeekChange}
          labelFunc={this.formatWeekSelectLabel}
          margin="dense"
          style={{ maxWidth: 340, marginRight: 10 }}
        />
      </MuiPickersUtilsProvider>
    );
  };

  renderCopyButton = () => {
    const { copying, loading, percent, filledUp } = this.state;
    if (copying) {
      return (
        <Button
          variant="outlined"
          color="default"
          disabled
          disableElevation
          startIcon={<CircularProgress size={15} />}
          style={{ marginTop: 4 }}
        >
          Updating {percent} ...
        </Button>
      );
    }
    return (
      <Button
        variant="outlined"
        color="primary"
        disableElevation
        disabled={loading || filledUp}
        style={{ marginTop: 4 }}
        onClick={() => this.copyRosterLastWeek()}
      >
        Copy from previous week
      </Button>
    );
  };

  renderPublictButton = () => {
    const { lock } = this.props;
    return (
      <Button
        variant="contained"
        color="primary"
        disableElevation
        disabled={!lock}
        style={{ marginTop: 4, float: "right" }}
        onClick={() => this.props.firestoreUnLockRoster({ lock })}
      >
        Publish Roster
      </Button>
    );
  };

  renderRoster = () => {
    const { loading } = this.state;
    const { branchs } = this.props;
    if (loading) {
      return (
        <Grid item md={12} xs={12}>
          <Loading loading={loading} />
        </Grid>
      );
    }
    if (branchs) {
      return branchs.map((item, index) => this.renderRosterTable({ item, index }));
    }
    return null;
  };

  renderRosterTable = ({ item, index }) => {
    const { dates, copying, loading } = this.state;
    return (
      <Grid item lg={6} md={12} xs={12} key={index}>
        <RosterTable branch={item} dates={dates} copying={copying} loading={loading} />
      </Grid>
    );
  };

  // ====================== Render Main ====================== //

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to={"/signin"} />;
    return (
      <div className="root-container">
        <CssBaseline />
        <Navbar title="Roster" right={<RightNav />} />
        <Container maxWidth="xl">
          <Grid container spacing={2} direction="row">
            <Grid item md={12} xs={12}>
              {this.renderPicker()}
              {this.renderCopyButton()}
              {this.renderPublictButton()}
            </Grid>
            {this.renderRoster()}
          </Grid>
        </Container>
              
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  branchs: state.branchReducer.branchs,
  rosters: state.rosterReducer.rosters,
  lock: state.rosterReducer.lock,
});

const mapDispatchToProps = {
  firestoreSnapShotUserList,
  firestoreSnapShotBranchList,
  firestoreSnapShotLockRoster,
  firestoreSnapShotRosters,
  unSnapShotUserList,
  unSnapShotBranchList,
  unSnapShotLockRoster,
  unSnapShotRosters,
  firestoreCopyRostersLastWeek,
  firestoreDeleteRostersThisWeek,
  firestoreLockRoster,
  firestoreUnLockRoster,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(RosterManagement);
