import {
  TIME_TRACKING_LIST_SUCCESS,
  TIME_TRACKING_TASK_SUCCESS,
  TIME_TRACKING_TASK_ERROR,
  TIME_TRACKING_RESET_TASK,
} from "../ref";

const initState = {
  timeTracking: null,
  tasks: [],
  errors: [],
};

const timeTrackingReducer = (state = initState, action) => {
  switch (action.type) {
    case TIME_TRACKING_LIST_SUCCESS:
      return {
        ...state,
        timeTracking: action.payload,
      };
    case TIME_TRACKING_TASK_SUCCESS:
      return {
        ...state,
        tasks: action.payload,
      };
    case TIME_TRACKING_TASK_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    case TIME_TRACKING_RESET_TASK:
      return {
        ...state,
        errors: [],
        tasks: [],
      };
    default:
      return state;
  }
};

export default timeTrackingReducer;
