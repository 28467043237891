import firebase from "firebase";
import moment from "moment";
import { allProgress } from "../../component/functions";
import {
  REF_TIME_TRACKING,
  TIME_TRACKING_LIST_SUCCESS,
  TASK_ADD,
  TASK_UPDATE,
  TASK_DELETE,
  TIME_TRACKING_TASK_SUCCESS,
  TIME_TRACKING_TASK_ERROR,
  TIME_TRACKING_RESET_TASK,
} from "../ref";

// -------------------------- Time Tracking List --------------------------

export const firestoreGetTimeTracking = ({ branch, from, to }, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const timeTrackingRef = firebase.firestore().collection(REF_TIME_TRACKING);
  const fromDate = moment(from).startOf("day")._d;
  const toDate = moment(to).endOf("day")._d;
  timeTrackingRef
    .where("owner", "==", owner)
    .where("branch", "==", branch)
    .where("checkedAt", ">=", fromDate)
    .where("checkedAt", "<=", toDate)
    .get()
    .then((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        getTimeTrackingSuccess(dispatch, data);
      }
      if (callback) {
        callback();
      }
    });
};

const subscribeTimes = [];
export const firestoreSnapShotTimeTracking = ({ branch, from, to }, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const timeTrackingRef = firebase.firestore().collection(REF_TIME_TRACKING);
  const fromDate = moment(from).startOf("day")._d;
  const toDate = moment(to).endOf("day")._d;
  const unsub = timeTrackingRef
    .where("owner", "==", owner)
    .where("branch", "==", branch)
    .where("checkedAt", ">=", fromDate)
    .where("checkedAt", "<=", toDate)
    .onSnapshot((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        getTimeTrackingSuccess(dispatch, data);
      }
      if (callback) {
        callback();
      }
    });
  subscribeTimes.push(unsub);
};

const getTimeTrackingSuccess = (dispatch, data) => {
  dispatch({
    type: TIME_TRACKING_LIST_SUCCESS,
    payload: data,
  });
};

export const unSnapShotTimeTracking = () => (dispatch) => {
  subscribeTimes.forEach((subscriber) => {
    // Xóa bỏ subscribe snapshot nếu out
    subscriber();
  });
  subscribeTimes.length = 0;
};

export const firestoreGetTimeTrackingByUser = ({ selectedUser, branch, date }, callback) => (
  dispatch
) => {
  const owner = selectedUser.owner;
  const user = selectedUser.id;
  const fromDate = moment(date).startOf("day")._d;
  const toDate = moment(date).endOf("day")._d;
  const timeTrackingRef = firebase.firestore().collection(REF_TIME_TRACKING);
  timeTrackingRef
    .where("owner", "==", owner)
    .where("branch", "==", branch)
    .where("user", "==", user)
    .where("checkedAt", ">=", fromDate)
    .where("checkedAt", "<=", toDate)
    .get()
    .then((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        if (callback) {
          callback(data);
        }
      } else {
        if (callback) {
          callback([]);
        }
      }
    });
};

// -------------------------- Time Tracking Add --------------------------

export const firestoreTimeTracking = ({ data }, callback) => (dispatch) => {
  const checkRef = firebase.firestore().collection(REF_TIME_TRACKING);
  const id = checkRef.doc().id;
  const newData = {
    ...data,
    id,
    createAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  checkRef.doc(id).set(newData, { merge: true });
  if (callback) {
    callback();
  }
};

// -------------------------- Time Tracking Task --------------------------

export const firestoreTimeTrackingHandleTasks = ({ tasks, branch }, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const addTasks = tasks.filter((task) => task.type === TASK_ADD);
  const updateTask = tasks.filter((task) => task.type === TASK_UPDATE);
  const deleteTask = tasks.filter((task) => task.type === TASK_DELETE);
  const addPromise = addTasks?.map((task) => {
    const addRef = firebase.firestore().collection(REF_TIME_TRACKING);
    const checkedAt = task?.timestamp;
    const user = task?.user;
    const id = addRef.doc().id;
    const data = {
      checkedAt,
      user,
      owner,
      branch,
      screenshot: "",
      subUrl: "admin",
      longitude: "",
      latitude: "",
      deviceName: "admin",
      id,
      createAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    return addRef.doc(id).set(data, { merge: true });
  });
  const updatePromise = updateTask?.map((task) => {
    const updateRef = firebase.firestore().collection(REF_TIME_TRACKING);
    const checkedAt = task.timestamp;
    const id = task.id;
    return updateRef.doc(id).update({ checkedAt });
  });
  const deletePromise = deleteTask?.map((task) => {
    const deleteRef = firebase.firestore().collection(REF_TIME_TRACKING);
    const id = task.id;
    return deleteRef.doc(id).delete();
  });
  const allPromise = [...addPromise, ...updatePromise, ...deletePromise];
  Promise.all(allPromise).then(() => {
    if (callback) {
      callback();
    }
  });
};

// -------------------------- Time Tracking Import --------------------------

export const firestoreImportTimeTrackingtByJson = ({ json, branch }, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const checkRef = firebase.firestore().collection(REF_TIME_TRACKING);
  checkRef
    .where("owner", "==", owner)
    .where("branch", "==", branch)
    .get()
    .then((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        const allPromise =
          json &&
          json.map((item) => {
            const exist = data.find((d) => d.id === item.id);
            if (exist) {
              // tồn tại, lấy id
              const id = exist.id;
              const newData = {
                ...exist,
                ...item, // ghi đè
                updateAt: firebase.firestore.FieldValue.serverTimestamp(),
              };
              return checkRef.doc(id).update(newData);
            } else {
              // tạo product data
              const id = checkRef.doc().id;
              const newData = {
                ...item,
                id,
                owner,
                branch,
              };
              return checkRef.doc(id).set(newData, { merge: true });
            }
          });
        allProgress(allPromise, (p) => {
          const percent = `${p.toFixed(0)}%`;
          if (p === 100 && callback) {
            callback("completed");
          } else {
            callback(percent);
          }
        });
      }
    });
};

// -------------------------- Time Tracking List --------------------------
export const setTasks = ({ tasks }) => (dispatch) => {
  setTasksSuccess(dispatch, tasks);
};

const setTasksSuccess = (dispatch, data) => {
  dispatch({
    type: TIME_TRACKING_TASK_SUCCESS,
    payload: data,
  });
};

export const setTasksError = ({ errors }) => (dispatch) => {
  setTasksErrorSuccess(dispatch, errors);
};

const setTasksErrorSuccess = (dispatch, data) => {
  dispatch({
    type: TIME_TRACKING_TASK_ERROR,
    payload: data,
  });
};

export const resetTasks = () => (dispatch) => {
  resetTasksSuccess(dispatch);
};

const resetTasksSuccess = (dispatch) => {
  dispatch({
    type: TIME_TRACKING_RESET_TASK,
  });
};
