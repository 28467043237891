import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CssBaseline, Container, Grid, Button } from "@material-ui/core";
import { AddBoxOutlined } from "@material-ui/icons";
import Navbar from "../00_Nav/nav_bar";
import RightNav from "../00_Nav/right_nav";
import BranchDetail from "./branch_detail";
import BranchTable from "./branch_table";
import {
  firestoreSnapShotBranchList,
  unSnapShotBranchList,
} from "../../store/actions/branch.action";
import { firestoreSnapShotUserList, unSnapShotUserList } from "../../store/actions/user.action";
import { MODE_ADD, MODE_EDIT, MODE_SELECT, MODE_NONE } from "../../config/stringConfig";

class BranchManagement extends Component {
  // ====================== Inits ====================== //

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      selectedBranch: null,
      mode: MODE_NONE,
    };
  }

  componentDidMount = () => {
    this.props.firestoreSnapShotUserList(); // snapshot users
    this.props.firestoreSnapShotBranchList(); // snapshot branchs
  };

  componentWillUnmount = () => {
    this.props.unSnapShotUserList();
    this.props.unSnapShotBranchList();
  };

  // ====================== Functions ====================== //

  onAdd = () => {
    this.setState({
      mode: MODE_ADD,
      selectedBranch: null,
    });
  };

  onCancel = () => {
    this.setState({
      mode: MODE_NONE,
      selectedBranch: null,
    });
  };

  onEdit = () => {
    this.setState({
      mode: MODE_EDIT,
    });
  };

  onSelect = () => {
    this.setState({
      mode: MODE_SELECT,
    });
  };

  onRowClick = (data) => {
    this.setState({
      selectedBranch: data.id,
      mode: MODE_SELECT,
    });
  };

  handleSelect = (e, id) => {
    this.setState({
      [id]: e.target.value,
    });
  };

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  renderLeft = () => {
    const { selectedBranch, mode } = this.state;
    return (
      <Grid item md={6} xs={12}>
        <BranchDetail
          branchId={selectedBranch}
          mode={mode}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          onSelect={this.onSelect}
        />
      </Grid>
    );
  };

  renderRight = () => {
    return (
      <Grid item md={6} xs={12} className="grid-user-group">
        <Grid container spacing={2} direction="row" justify="center" alignItems="stretch">
          <Grid item xs={4}>
            <h3 className="regular-title" style={{ flex: 1 }}>
              Branch List
            </h3>
          </Grid>
          <Grid item xs={8}>
            <Button
              className="add-btn"
              variant="outlined"
              color="primary"
              startIcon={<AddBoxOutlined />}
              onClick={this.onAdd}
            >
              Add Branch
            </Button>
          </Grid>
        </Grid>
        <BranchTable onRowClick={this.onRowClick} />
      </Grid>
    );
  };

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to={"/signin"} />;
    return (
      <div className="root-container">
        <CssBaseline />
        <Navbar title="Branch Management" right={<RightNav />} />
        <Container maxWidth="xl">
          <Grid container spacing={2} direction="row" justify="center" alignItems="stretch">
            {this.renderLeft()}
            {this.renderRight()}
          </Grid>
        </Container>
              
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  users: state.userReducer.users,
  branchs: state.branchReducer.branchs,
});

const mapDispatchToProps = {
  firestoreSnapShotUserList,
  firestoreSnapShotBranchList,
  unSnapShotUserList,
  unSnapShotBranchList,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BranchManagement);
