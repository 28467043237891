import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import { orderBy, sumBy } from "lodash";
import dateArray from "moment-array-dates";

momentDurationFormatSetup(moment); // setup cho moment duration format
// cải thiện dữ liệu query được biến thành dạng dữ liệu xử lý được

export const timeTrackingToReport = ({ users, timeTracking, from, to }) => {
  //B1: Nhóm theo từng user rồi phân tích dữ liệu theo name, date, day theo cụm array
  const noEmpty = filterByUser({ users, timeTracking });
  //B2:  Nhóm từng cụm này theo ngày
  const fromString = moment(from).format("DD/MM/YYYY");
  const toString = moment(to).format("DD/MM/YYYY");
  const dates =
    fromString === toString
      ? [fromString]
      : dateArray.range(moment(from), moment(to), "DD/MM/YYYY", true);
  let range = 0;
  const byDate = noEmpty?.map((item) => {
    const result = [];
    dates.forEach((d) => {
      let data = item?.filter((i) => i.date === d); // data theo ngày
      const length = data.length;
      if (length > 0) {
        if (length > range) {
          // tìm max
          range = length;
        }
        // Lấy thông tin từng row
        const row = dataToRow(data);
        result.push(row);
      }
    });
    const sumBreaking = sumBy(result, "breakSeconds");
    const sumWorking = sumBy(result, "workSeconds");
    const fullName = result[0].fullName;
    const username = result[0].username;
    return {
      result,
      sumBreaking,
      sumWorking,
      fullName,
      username,
    };
  });
  const totalBreaking = sumBy(byDate, "sumBreaking");
  const totalWorking = sumBy(byDate, "sumWorking");
  const report = {
    range,
    data: byDate,
    totalBreaking,
    totalWorking,
  };
  return report;
};

const filterByUser = ({ users, timeTracking }) => {
  const filter = users?.map((item) => {
    const fullName = `${item?.firstName} ${item?.lastName}`;
    const username = item?.username;
    const data = timeTracking?.filter((t) => t.user === item.id);
    const result = data?.map((d) => {
      const checkedAt = d?.checkedAt?.seconds ? moment(d?.checkedAt.toDate()) : null;
      const date = checkedAt?.format("DD/MM/YYYY");
      const day = checkedAt?.format("ddd");
      return {
        ...d,
        day,
        date,
        fullName,
        username,
      };
    });
    return result;
  });
  return filter.filter((item) => item.length !== 0);
};

const dataToRow = (data) => {
  // Lấy thông tin từng row
  const fullName = data[0]?.fullName;
  const username = data[0]?.username;
  const date = data[0]?.date;
  const day = data[0]?.day;
  const user = data[0]?.user;
  const ordered = orderBy(data, "checkedAt", "asc");
  let workSeconds = 0;
  const length = ordered.length;
  const max = length % 2 === 0 ? length : length - 1;
  const check = ordered?.map((item, index) => {
    const checkedAt = item?.checkedAt;
    const seconds = checkedAt?.seconds;
    workSeconds =
      index < max ? (index % 2 === 0 ? workSeconds - seconds : workSeconds + seconds) : workSeconds;
    const type = index % 2 === 0 ? "in" : "out";
    const timestamp = seconds && checkedAt.toDate();
    const time = moment(timestamp).format("hh:mm A");
    const id = item?.id;
    const screenshot = item?.screenshot;
    const subUrl = item?.subUrl;
    return {
      seconds,
      timestamp,
      time,
      id,
      item,
      type,
      screenshot,
      subUrl,
    };
  });
  let total = max > 1 ? check[max - 1].seconds - check[0]?.seconds : 0;
  const breakSeconds = total - workSeconds;
  const row = {
    fullName,
    username,
    date,
    day,
    check,
    total,
    user,
    workSeconds,
    breakSeconds,
  };
  return row;
};

export const getHeaderByRange = (range) => {
  let midHeader = [];
  if (range) {
    for (let i = 0; i < range; i++) {
      const number = Math.round(i / 2) + (i % 2 === 0 ? 1 : 0);
      const id = i % 2 === 0 ? `in${number}` : `out${number}`;
      const label = i % 2 === 0 ? `In ${number}` : `Out ${number}`;
      const data = { id, label, csvLabel: label, align: "center", sortable: false };
      midHeader = [...midHeader, data];
    }
  }
  const preHeader = [
    { id: "username", label: "Username", csvLabel: "Username", align: "left", sortable: false },
    { id: "fullName", label: "Full Name", csvLabel: "Fullname", align: "left", sortable: true },
    { id: "date", label: "Date", csvLabel: "Date", align: "center", sortable: false },
    { id: "day", label: "Day", csvLabel: "Day", align: "center", sortable: false },
  ];
  const endHeader = [
    {
      id: "workingtime",
      label: "Working Time",
      csvLabel: "Working Time",
      align: "center",
      sortable: false,
    },
    {
      id: "breakingtime",
      label: "Breaking Time",
      csvLabel: "Breaking Time",
      align: "center",
      sortable: false,
    },
  ];
  const header = [...preHeader, ...midHeader, ...endHeader];
  return header;
};

// Chuyến giây sang giờ phút
export const secondsToHoursAndMinuteFormat = (seconds) => {
  if (seconds && seconds >= 0) {
    const time = moment.duration(seconds, "seconds").format("HH:mm", { trim: false });
    return time;
  }
  return "00:00";
};
