import { ROSTER_LIST_SUCCESS, LOCK_ROSTER_LIST_SUCCESS } from "../ref";

const initState = {
  rosters: null,
  lock: null,
};

const rosterReducer = (state = initState, action) => {
  switch (action.type) {
    case ROSTER_LIST_SUCCESS:
      return {
        ...state,
        rosters: action.payload,
      };
    case LOCK_ROSTER_LIST_SUCCESS:
      return {
        ...state,
        lock: action.payload,
      };
    default:
      return state;
  }
};

export default rosterReducer;
