export const APP_NAME = "ProfessioNail Operation"; // App Name
export const MODE_SELECT = "select";
export const MODE_EDIT = "edit";
export const MODE_ADD = "add";
export const MODE_NONE = "none";
export const CAMERA = "camera";
export const UPLOAD = "upload";
export const REQUEST_SICK = "Sick leave";
export const REQUEST_PLACE = "Change Workplace";
export const REQUEST_TIME = "Change Worktime";
export const REQUEST_OFF = "Request Day Off";
export const REQUEST_SENT = "Sent";
export const REQUEST_ALL = "All";
export const REQUEST_REJECTED = "Rejected";
export const REQUEST_ACCEPTED = "Accepted";
export const LEVELS = [
  { name: "Trainee", color: "#fd9644" },
  { name: "Level 1", color: "#eb3b5a" },
  { name: "Level 2", color: "#20bf6b" },
  { name: "Level 3", color: "#4b7bec" },
  { name: "Level 4", color: "#a55eea" },
];
