import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import { VisibilityOutlined, CloudDownloadOutlined } from "@material-ui/icons";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

class TimeTrackingFromTo extends Component {
  // ====================== Inits ====================== //

  // ====================== Functions ====================== //

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderFrom = () => {
    const { from, to, disabled } = this.props;
    return (
      <Grid item md={2} xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            autoOk
            className="date-picker"
            variant="inline"
            inputVariant="outlined"
            fullWidth
            value={from}
            maxDate={to}
            label="From"
            format="DD/MM/YYYY"
            InputAdornmentProps={{ position: "end" }}
            onChange={(value) => this.props.onDateChange("from", value)}
            margin="dense"
            disabled={disabled}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    );
  };

  renderTo = () => {
    const { from, to, disabled } = this.props;
    return (
      <Grid item md={2} xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            autoOk
            className="date-picker"
            variant="inline"
            inputVariant="outlined"
            fullWidth
            minDate={from}
            value={to}
            label="To"
            format="DD/MM/YYYY"
            InputAdornmentProps={{ position: "end" }}
            onChange={(value) => this.props.onDateChange("to", value)}
            margin="dense"
            disabled={disabled}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    );
  };

  renderViewButton = () => {
    const { loading, disabled } = this.props;
    return (
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          style={{ marginTop: 3 }}
          startIcon={loading ? <CircularProgress size={15} /> : <VisibilityOutlined />}
          onClick={() => this.props.onViewClick()}
        >
          View
        </Button>
      </Grid>
    );
  };

  renderDownloadButton = () => {
    const { disabled } = this.props;
    return (
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          style={{ marginTop: 3 }}
          startIcon={<CloudDownloadOutlined />}
          onClick={() => this.props.onDownLoadClick()}
        >
          Download
        </Button>
      </Grid>
    );
  };

  // ====================== Render Main ====================== //

  render() {
    return (
      <Grid container spacing={3} direction="row" justify="flex-start" alignItems="center">
        {this.renderFrom()}
        {this.renderTo()}
        {this.renderViewButton()}
        {this.renderDownloadButton()}
      </Grid>
    );
  }
}

// const mapStateToProps = (state) => ({
//   branchs: state.branchReducer.branchs,
// });

// const mapDispatchToProps = {};

export default compose(connect(null, null))(TimeTrackingFromTo);
