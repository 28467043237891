import moment from "moment";
import { LEVELS } from "../config/stringConfig";

export const MODEL_USER = {
  model: {
    id: null,
    avatar: "",
    active: true,
    shopRoster: false,
    individualRoster: false,
    username: "",
    pincode: "1111",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    level: LEVELS[0]?.name,
    dob: moment().subtract(14, "years").format("01/01/YYYY"),
  },
  error: {
    username: "",
    pincode: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  },
  require: {
    username: true,
    pincode: true,
    firstName: true,
    lastName: true,
    level: true,
  },
  regex: {
    username: /^[a-zA-Z0-9]{4,32}$/, // 4-32 không kí tự, space
    pincode: /^[0-9]{4}$/, // 4 số
    phone: /^[0-9.+\-,()\s]{9,13}$/, // 9 tới 11 số , kèm . + - ()
    // eslint-disable-next-line no-useless-escape
    email:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // Email format include +
  },
  inputRegex: {
    username: /^[a-zA-Z0-9]{0,32}$/,
    pincode: /^[0-9]{0,4}$/,
    phone: /^[0-9.+\-,()\s]{0,13}$/,
  },
  errorMess: {
    username: "Must contain 4-32 characters, numbers and alphabets.",
    pincode: "Must contain 4 characters, numbers only.",
    phone: "Invalid Phone Number.",
    email: "Invalid Email Addresss.",
  },
};
