import firebase from "firebase";
import moment from "moment";
import { REF_ROSTER, ROSTER_LIST_SUCCESS, REF_LOCK_ROSTER, LOCK_ROSTER_LIST_SUCCESS } from "../ref";

// -------------------------- Roster List --------------------------

const subscribeRosters = [];
export const firestoreSnapShotRosters = ({ from, to }, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const rosterRef = firebase.firestore().collection(REF_ROSTER);
  const start = moment(from)._d;
  const end = moment(to)._d;
  const unsubRosterList = rosterRef
    .where("owner", "==", owner)
    .where("workAt", ">=", start)
    .where("workAt", "<=", end)
    .onSnapshot((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        getGetRosterListSuccess(dispatch, data);
        if (callback) {
          callback();
        }
      }
    });
  subscribeRosters.push(unsubRosterList);
};

const getGetRosterListSuccess = (dispatch, data) => {
  dispatch({
    type: ROSTER_LIST_SUCCESS,
    payload: data,
  });
};

export const unSnapShotRosters = () => (dispatch) => {
  subscribeRosters.forEach((subscriber) => {
    // Xóa bỏ subscribe snapshot nếu out
    subscriber();
  });
  subscribeRosters.length = 0;
};

// -------------------------- Roster Add--------------------------

export const firestoreAddOrUpdateRoster = ({ roster }, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const rosterRef = firebase.firestore().collection(REF_ROSTER);
  const workAt = moment(roster.workAt)._d;
  const endAt = moment(roster.endAt)._d;
  if (roster.id) {
    // đã có, update
    const data = {
      // tạo branch data
      ...roster,
      workAt,
      endAt,
    };
    rosterRef
      .doc(roster.id)
      .update(data)
      .then(() => {
        if (callback) {
          callback();
        }
      });
  } else {
    // chưa có, addd
    const id = rosterRef.doc().id;
    const data = {
      // tạo branch data
      ...roster,
      id,
      createAt: firebase.firestore.FieldValue.serverTimestamp(),
      owner,
      workAt,
      endAt,
    };
    rosterRef
      .doc(id)
      .set(data, { merge: true })
      .then(() => {
        if (callback) {
          callback();
        }
      });
  }
};

// -------------------------- Roster Update--------------------------

export const firestoreRosterWeekUpdate = ({ rosters }, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const rosterRef = firebase.firestore().collection(REF_ROSTER);
  rosters.forEach((roster) => {
    const workAt = moment(roster.workAt)._d;
    const endAt = moment(roster.endAt)._d;
    if (roster.id) {
      // tồn tại, lấy id
      const id = roster.id;
      const newData = {
        ...roster,
        workAt,
        endAt,
        updateAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      rosterRef.doc(id).update(newData);
    } else {
      // tạo id data
      const id = rosterRef.doc().id;
      const newData = {
        ...roster,
        id,
        createAt: firebase.firestore.FieldValue.serverTimestamp(),
        owner,
        workAt,
        endAt,
      };
      rosterRef.doc(id).set(newData, { merge: true });
    }
  });
  if (callback) {
    callback();
  }
};

// -------------------------- Roster Delete--------------------------

export const firestoreDeleteRostersThisWeek = ({ from, to }, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const rosterRef = firebase.firestore().collection(REF_ROSTER);
  const start = moment(from)._d;
  const end = moment(to)._d;
  rosterRef
    .where("owner", "==", owner)
    .where("workAt", ">=", start)
    .where("workAt", "<=", end)
    .get()
    .then((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        data.forEach((roster) => {
          const id = roster.id;
          rosterRef.doc(id).delete();
        });
        if (callback) {
          callback();
        }
      }
    });
};

// -------------------------- Roster Copy--------------------------

export const firestoreCopyRostersLastWeek = ({ from, to }, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const rosterRef = firebase.firestore().collection(REF_ROSTER);
  const start = moment(from).subtract(1, "week")._d;
  const end = moment(to).subtract(1, "week")._d;
  rosterRef
    .where("owner", "==", owner)
    .where("workAt", ">=", start)
    .where("workAt", "<=", end)
    .get()
    .then((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        data.forEach((roster) => {
          // Kiểm tra thời gian
          const workAt = roster.workAt;
          const newWorkAt = workAt && workAt.seconds && moment(workAt.toDate()).add(1, "week")._d;
          const endAt = roster.endAt;
          const newEndAt = endAt && endAt.seconds && moment(endAt.toDate()).add(1, "week")._d;
          // Thiết lập ngày
          const date = moment(roster.date, "ddd DD MMM YYYY")
            .add(1, "week")
            .format("ddd DD MMM YYYY");
          // tạo id data
          const id = rosterRef.doc().id;
          const newData = {
            ...roster,
            id,
            createAt: firebase.firestore.FieldValue.serverTimestamp(),
            updateAt: firebase.firestore.FieldValue.serverTimestamp(),
            owner,
            workAt: newWorkAt,
            endAt: newEndAt,
            date,
          };
          rosterRef.doc(id).set(newData, { merge: true });
        });
        if (callback) {
          callback();
        }
      }
    });
};

// -------------------------- Roster Lock--------------------------

export const firestoreLockRoster = ({ from, to }, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const lockRef = firebase.firestore().collection(REF_LOCK_ROSTER);
  const start = moment(from)._d;
  const end = moment(to)._d;
  const id = lockRef.doc().id;
  const data = {
    id,
    start,
    end,
    owner,
    createAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  lockRef.doc(id).set(data, { merge: true });
};

export const firestoreUnLockRoster = ({ lock }, callback) => (dispatch) => {
  const lockRef = firebase.firestore().collection(REF_LOCK_ROSTER);
  const id = lock?.id;
  lockRef.doc(id).delete();
};

const subscribeLock = [];
export const firestoreSnapShotLockRoster = ({ from, to }, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const lockRef = firebase.firestore().collection(REF_LOCK_ROSTER);
  const start = moment(from)._d;
  const end = moment(to)._d;
  const unSub = lockRef
    .where("owner", "==", owner)
    .where("start", ">=", start)
    .where("start", "<=", end)
    .onSnapshot((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        const lock = data[0] || null;
        getGetLockRosterListSuccess(dispatch, lock);
        if (callback) {
          callback();
        }
      }
    });
  subscribeLock.push(unSub);
};

const getGetLockRosterListSuccess = (dispatch, data) => {
  dispatch({
    type: LOCK_ROSTER_LIST_SUCCESS,
    payload: data,
  });
};

export const unSnapShotLockRoster = () => (dispatch) => {
  subscribeLock.forEach((subscriber) => {
    // Xóa bỏ subscribe snapshot nếu out
    subscriber();
  });
  subscribeLock.length = 0;
};
