import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { DoneAllOutlined } from "@material-ui/icons";
import { getHeaderByRange } from "../../util/refine.timetracking";
import { MODE_EDIT } from "../../config/stringConfig";
import TimeTrackingEditRow from "./component/time_tracking_edit_row";
import {
  resetTasks,
  firestoreTimeTrackingHandleTasks,
} from "../../store/actions/time_tracking.action";

class TimeTrackingEditTable extends Component {
  // ====================== Inits ====================== //

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      reportData: null,
    };
  }

  // Chuyển state từ props
  static getDerivedStateFromProps(nextProps, prevState) {
    const oldMode = prevState.mode;
    const newMode = nextProps.mode;
    if (oldMode !== newMode && newMode === MODE_EDIT) {
      const reportData = nextProps.reportData;
      return {
        mode: newMode,
        reportData,
      };
    }
    return null;
  }

  // ====================== Functions ====================== //

  onCancel = () => {
    this.props.handleCancel();
    this.props.resetTasks();
  };

  onSuccess = () => {
    this.props.handleSuccess();
    this.props.resetTasks();
  };

  // ====================== Data Functions ====================== //

  // ====================== Firebase Functions ====================== //

  updateEditedRecord = () => {
    const { tasks, branch } = this.props;
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.firestoreTimeTrackingHandleTasks({ tasks, branch }, () => {
          this.setState(
            {
              loading: false,
            },
            () => {
              this.onSuccess();
            }
          );
        });
      }
    );
  };

  // ====================== Render Component ====================== //

  renderButtonBar = () => {
    return (
      <Grid container spacing={3} direction="row" justify="flex-end" alignItems="center">
        {this.renderCancelButton()}
        {this.renderConfirmButton()}
      </Grid>
    );
  };

  renderCancelButton = () => {
    const { editLoading } = this.state;
    return (
      <Grid item>
        <Button
          variant="contained"
          color="default"
          style={{ marginTop: 3 }}
          disabled={editLoading}
          onClick={() => this.onCancel()}
        >
          Cancel
        </Button>
      </Grid>
    );
  };

  renderConfirmButton = () => {
    const { errors, tasks } = this.props;
    const { loading } = this.state;
    const disabled = errors.length !== 0 || tasks.length === 0;
    return (
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 3, backgroundColor: disabled ? "#e0e0e0" : "#4caf50" }}
          disabled={disabled}
          startIcon={loading ? <CircularProgress size={15} /> : <DoneAllOutlined />}
          onClick={() => this.updateEditedRecord()}
        >
          Confirm
        </Button>
      </Grid>
    );
  };

  renderTableHead = () => {
    const { range } = this.props;
    const header = getHeaderByRange(range);
    return (
      <TableHead>
        <TableRow>
          {header?.map((headCell) => (
            <TableCell key={headCell.id} align={headCell.align} padding="default">
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  renderTableBody = () => {
    const { reportData } = this.state;
    return (
      <TableBody>
        {reportData?.map((section, key) => this.renderTableSections({ section, key }))}
      </TableBody>
    );
  };

  renderTableSections = ({ section, key }) => {
    const data = section?.result;
    const { range } = this.props;
    return (
      <React.Fragment key={key}>
        {data?.map((row, index) => (
          <TimeTrackingEditRow row={row} index={index} key={index} range={range} />
        ))}
      </React.Fragment>
    );
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    return (
      <React.Fragment>
        {this.renderButtonBar()}
        <Paper style={{ width: "100%", marginTop: 10, marginBottom: 30 }}>
          <TableContainer className="report-table  time-tracking-table">
            <Table stickyHeader aria-label="sticky table">
              {this.renderTableHead()}
              {this.renderTableBody()}
            </Table>
          </TableContainer>
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  tasks: state.timeTrackingReducer.tasks,
  errors: state.timeTrackingReducer.errors,
});

const mapDispatchToProps = {
  resetTasks,
  firestoreTimeTrackingHandleTasks,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TimeTrackingEditTable);
