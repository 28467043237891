import React, { Component } from "react";
import { connect } from "react-redux";
import { IconButton, Badge, Link } from "@material-ui/core";
import { Email } from "@material-ui/icons";
import { firestoreSnapshotPendingRequest, unSubBadge } from "../../store/actions/request.action";
import { URL } from "../../config/config";

class RightNav extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  componentDidMount = () => {
    this.props.firestoreSnapshotPendingRequest();
  };

  componentWillUnmount = () => {
    this.props.unSubBadge();
  };

  render() {
    const { pendingRequest } = this.props;
    const length = pendingRequest?.length;
    return (
      <Link href={URL.request}>
        <IconButton aria-label="cart">
          <Badge badgeContent={length} color="secondary">
            <Email style={{ color: "#fff" }} />
          </Badge>
        </IconButton>
      </Link>
    );
  }
}

const mapStateToProps = (state) => ({
  pendingRequest: state.requestReducer.pendingRequest,
});

const mapDispatchToProps = {
  firestoreSnapshotPendingRequest,
  unSubBadge,
};

export default connect(mapStateToProps, mapDispatchToProps)(RightNav);
