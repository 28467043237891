import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { TableCell, FormControl, IconButton } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { TASK_ADD, TASK_UPDATE, TASK_DELETE } from "../../../store/ref";
import { setTasks, setTasksError } from "../../../store/actions/time_tracking.action";

class TimeTrackingEditCell extends Component {
  // ====================== Inits ====================== //

  constructor(props) {
    super(props);
    this.state = {
      value: null,
      editting: false,
    };
  }
  // ====================== Functions ====================== //

  handleTimeChange = ({ value }) => {
    this.setState({
      value,
      editting: true,
    });
  };

  handleCloseTimePicker = () => {
    setTimeout(() => {
      this.validateTask();
    }, 100);
  };

  handleTimeDelete = () => {
    const { data, index } = this.props;
    const next = data[index + 1];
    const isNextValid = next ? next?.type === TASK_DELETE : true;
    this.setState(
      {
        error: !isNextValid,
        value: "empty",
        editting: true,
      },
      () => {
        if (isNextValid) {
          this.props.onValid();
          this.removeTaksError();
          setTimeout(() => {
            this.handleDeleteTask();
          }, 100);
        } else {
          this.props.onError();
          this.addTaskError();
        }
      }
    );
  };
  // ====================== Data Functions ====================== //

  // Kiểm tra tính hợp lý
  validateTask = () => {
    const { value } = this.state;
    const { row } = this.props;
    const time = moment(value).format("hh:mm A");
    const string = `${row?.date} ${time}`;
    const momentTime = moment(string, "DD/MM/YYYY hh:mm A");
    const validBefore = this.isValidBefore({ momentTime });
    const validAfter = this.isValidAfter({ momentTime });
    if (validBefore && validAfter) {
      this.setState(
        {
          error: false,
        },
        () => {
          this.props.onValid();
          this.removeTaksError();
          this.handleUpdateAddTask({ value });
        }
      );
    } else {
      this.setState(
        {
          error: true,
        },
        () => {
          this.props.onError();
          this.addTaskError();
        }
      );
    }
  };

  isValidBefore = ({ momentTime }) => {
    const { data, index } = this.props;
    const pre = data?.length > 0 ? data[index - 1]?.timestamp : null;
    const preMoment = pre && moment(pre);
    const validBefore = preMoment ? preMoment.isBefore(momentTime) : true;
    return validBefore;
  };

  isValidAfter = ({ momentTime }) => {
    const { data, index } = this.props;
    const next = data[index + 1]?.timestamp;
    const nextMoment = next && moment(next);
    const validAfter = nextMoment ? nextMoment.isAfter(momentTime) : true;
    return validAfter;
  };

  // ====================== Data Functions ====================== //

  handleUpdateAddTask = ({ value }) => {
    const { row, index } = this.props;
    const date = row?.date;
    const username = row?.username;
    const user = row?.user;
    const exist = row?.check[index];
    const time = moment(value).format("hh:mm A");
    const timestamp = moment(`${date} ${time}`, "DD/MM/YYYY hh:mm A")._d;
    const data = {
      date,
      username,
      user,
      time,
      index,
      timestamp,
      id: exist?.id,
      type: exist ? TASK_UPDATE : TASK_ADD,
    };
    this.addTask(data);
  };

  handleDeleteTask = () => {
    const { row, index } = this.props;
    const date = row?.date;
    const username = row?.username;
    const exist = row?.check[index];
    if (exist) {
      const data = {
        date,
        username,
        index,
        id: exist?.id,
        type: TASK_DELETE,
      };
      this.addTask(data);
    } else {
      this.removeTask();
    }
  };

  addTask = (data) => {
    const { row, tasks, index } = this.props;
    const date = row?.date;
    const username = row?.username;
    const filter = tasks?.filter((item) => {
      if (item.username === username && item.date === date && item.index === index) {
        return false;
      }
      return true;
    });
    const newTasks = [...filter, data];
    this.props.setTasks({ tasks: newTasks });
  };

  removeTask = () => {
    const { row, tasks, index } = this.props;
    const date = row?.date;
    const username = row?.username;
    const filter = tasks?.filter((item) => {
      if (item.username === username && item.date === date && item.index === index) {
        return false;
      }
      return true;
    });
    this.props.setTasks({ tasks: filter });
  };

  addTaskError = () => {
    const { errors, row, index } = this.props;
    const username = row?.username;
    const date = row?.date;
    const data = {
      username,
      date,
      index,
    };
    const newError = [...errors, data];
    this.props.setTasksError({ errors: newError });
  };

  removeTaksError = () => {
    const { errors, row, index } = this.props;
    const username = row?.username;
    const date = row?.date;
    const filter = errors?.filter((item) => {
      if (item.username === username && item.date === date && item.index === index) {
        return false;
      }
      return true;
    });
    this.props.setTasksError({ errors: filter });
  };

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderTimeTracking = () => {
    const { time, disabled } = this.props;
    const { value, error, editting } = this.state;
    const classEdit = editting ? "edited" : "";
    const classError = error ? "error" : "";
    const className = `time-picker-scroll edit-table-cell ${classEdit} ${classError}`;
    const isDisabled = error ? false : disabled;
    const timeValue = value === "empty" ? null : value || time;
    return (
      <FormControl fullWidth>
        <TimePicker
          picker="time"
          showSecond={false}
          value={timeValue}
          className={className}
          format="hh:mm A"
          onChange={(value) => this.handleTimeChange({ value })}
          onClose={this.handleCloseTimePicker}
          use12Hours
          inputReadOnly
          disabled={isDisabled}
          clearIcon={null}
          minuteStep={5}
        />
      </FormControl>
    );
  };

  renderDeleteButton = () => {
    const { error } = this.state;
    const { disabled, data, index } = this.props;
    const isDisabled = error ? false : disabled;
    const next = data[index + 1];
    const isNextValid = next ? next?.type === TASK_DELETE : true;
    if (error || (isNextValid && data[index])) {
      return (
        <IconButton
          aria-label="delete"
          size="small"
          className="edit-delete"
          disabled={isDisabled}
          onClick={() => this.handleTimeDelete()}
        >
          <Cancel fontSize="small" style={{ color: "red" }} />
        </IconButton>
      );
    }
    return null;
  };

  // ====================== Render Main ====================== //

  render() {
    return (
      <React.Fragment>
        <TableCell align="center" className="report-data-cell edit">
          <div style={{ display: "flex" }}>
            {this.renderTimeTracking()}
            {this.renderDeleteButton()}
          </div>
        </TableCell>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  tasks: state.timeTrackingReducer.tasks,
  errors: state.timeTrackingReducer.errors,
});

const mapDispatchToProps = {
  setTasks,
  setTasksError,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TimeTrackingEditCell);
